/**
 * Gestionar instancias de axios para distintas configuraciones y apis
 * @module app/api/_instancias
 */

import axios from 'axios';
import * as storage from '../storage';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

let store;

export const injectDeviceHashToAxiosInstances = (_store) => {
  store = _store;
};

// let cfg = localStorage.getItem('cfg');
// if (cfg) {
//   cfg = JSON.parse(cfg);
// }

/**
 * Instancia de axios para interno
 */
export const interno = axios.create({
  name: 'interno',
  baseURL: process.env.REACT_APP_API_INTERNO
});

/**
 * Instancia de axios para autenticación
 */
export const autenticacion = axios.create({
  name: 'autenticacion',
  baseURL: process.env.REACT_APP_API_AUTENTICACION
});

/**
 * Instancia de axios para autorización
 */
export const autorizacion = axios.create({
  name: 'autorizacion',
  baseURL: process.env.REACT_APP_API_AUTORIZACION
});

/**
 * Instancia de axios para nc
 */
export const nc = axios.create({
  name: 'nc',
  // baseURL: process.env['REACT_APP_API_NC' + (cfg?.nc?.legacy === true ? '_LEGACY' : '')]
  baseURL: process.env['REACT_APP_API_NC']
});

/**
 * Instancia de axios para rv
 */
export const rv = axios.create({
  name: 'rv',
  // baseURL: process.env['REACT_APP_API_RV' + (cfg?.rv?.legacy === true ? '_LEGACY' : '')]
  baseURL: process.env['REACT_APP_API_RV']
});

const waitUntil = async (condition, time = 100) => {
  while (!condition()) {
    await new Promise((resolve) => setTimeout(resolve, time));
  }
};

// evitar multiples llamadas consecutivas para refrescar el token
const RefrescarToken = (function () {
  function NuevoToken() {
    this.id = Math.random();
    this.token = null;
    this.solicitando = false;

    this.obtener = async function (viejoToken) {
      if (!this.solicitando) {
        if (this.token) {
          this.token = null;
        }

        if (!this.token) {
          this.solicitando = true;

          const { data } = await axios.get(`${process.env.REACT_APP_API_AUTENTICACION}/refresh`, {
            headers: {
              Authorization: `Bearer ${viejoToken}`,
              'Device-Hash': store.getState().dataUsuario.deviceHash,
              'Client-IP': store.getState().dataUsuario.ip
            }
          });

          this.token = data.token;
          this.solicitando = false;
        }
      }

      await waitUntil(() => this.token !== null);

      return this.token;
    };
  }

  let instancia;

  return {
    nuevoToken: function () {
      if (!instancia) {
        instancia = new NuevoToken();
        delete instancia.constructor;
      }

      return instancia;
    }
  };
})();

const requestInterceptor = async (request, instancia) => {
  await waitUntil(
    () => store.getState().dataUsuario.deviceHash !== null && store.getState().dataUsuario.ip !== null
  );

  let token = storage.token.obtener();

  let hash = store.getState().dataUsuario.deviceHash;
  let ip = store.getState().dataUsuario.ip;

  request.headers['Device-Hash'] = hash;
  request.headers['Client-IP'] = ip;

  if (request.data instanceof FormData) {
    delete request.headers['Content-Type'];
  }

  if (!request.noToken && !!token && !request.url.includes('/login_check')) {
    if (
      !storage.token.esValido() ||
      (instancia.defaults.name === 'autenticacion' && request.url.includes('/refresh'))
    ) {
      try {
        const nuevoToken = await RefrescarToken.nuevoToken().obtener(token);

        storage.token.guardar(nuevoToken);
        token = nuevoToken;
      } catch (error) {
        const controller = new AbortController();

        storage.token.remover();
        controller.abort();

        return { ...request, signal: controller.signal };
      }
    }

    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

const responseInterceptor = async (error, instancia) => {
  if (error.code !== 'ERR_CANCELED') {
    let request = error.config;

    if (
      !request.url.includes('/refresh') &&
      !request.url.includes('/login_check') &&
      !request.url.includes('password/restablecer')
    ) {
      if (error.response.status === 401) {
        if (!storage.token.esValido()) {
          let token = storage.token.obtener();
          let hash = store.getState().dataUsuario.deviceHash;
          let ip = store.getState().dataUsuario.ip;

          try {
            const nuevoToken = await RefrescarToken.nuevoToken().obtener(token);

            storage.token.guardar(nuevoToken);
            token = nuevoToken;

            request.headers.Authorization = `Bearer ${token}`;
            request.headers['Device-Hash'] = hash;
            request.headers['Client-IP'] = ip;

            return instancia(request);
          } catch (error) {
            const controller = new AbortController();

            storage.token.remover();
            controller.abort();

            return { ...request, signal: controller.signal };
          }
        }

        storage.token.remover();
        window.location.reload();
      }
    }
  }

  return Promise.reject(error);
};

for (let instancia of [interno, autenticacion, autorizacion, nc, rv]) {
  instancia.interceptors.request.use(async (request) => await requestInterceptor(request, instancia));

  instancia.interceptors.response.use(
    function (response) {
      return response;
    },
    async (error) => await responseInterceptor(error, instancia)
  );
}
