import { FormControl, MenuItem, Select } from '@mui/material';

function SelectorGrupoModelo({ grupo, onCambioGrupo, grupos }) {
  return (
    grupos.length > 1 && (
      <FormControl sx={{ minWidth: 80 }}>
        <Select
          sx={css.select}
          autoWidth
          labelId='SelectorGrupoModelo'
          value={grupo ?? ''}
          onChange={(evt) => onCambioGrupo(evt.target.value)}
        >
          {grupos.map((grp) => (
            <MenuItem key={grp} value={grp} sx={css.menuitem}>
              {grp.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
}

const css = {
  select: {
    borderBottom: '3px solid #539170',
    borderRadius: 2,
    '&:hover': { backgroundColor: '#FFF' },
    '& fieldset': {
      border: 0
    },
    '& .MuiSelect-select': {
      fontSize: { xs: 24, lg: 32 },
      padding: 0,
      verticalAlign: 'middle',
      textTransform: 'capitalize',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontWeight: 600,
      marginLeft: 1
    }
  },
  menuitem: {
    '&:hover': { backgroundColor: '#f5fcf8' }
  }
};

export default SelectorGrupoModelo;
