import { useLocation } from 'react-router-dom';

import { Breadcrumbs, Skeleton, Toolbar, Typography } from '@mui/material';
import { DrawerHeader } from './utils';
import { grey } from '@mui/material/colors';
import { NavigateNext } from '@mui/icons-material';

import { TLink } from '../thallus';

function Navbar({ menu, servicioActivo }) {
  return (
    <DrawerHeader sx={{ px: 0, position: 'fixed', width: '100%', zIndex: 1000 }}>
      <Toolbar sx={{ pt: 5, width: '100%', backgroundColor: grey[200] }}>
        {!!menu ? (
          <Breadbar menu={menu} servicioActivo={servicioActivo} />
        ) : (
          <Typography sx={{ width: '100%', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
            <Skeleton variant='text' width={100} animation='wave' sx={{ display: 'inline-block' }} />
            <NavigateNext fontSize='small' />
            <Skeleton variant='text' width={100} animation='wave' sx={{ display: 'inline-block' }} />
            <NavigateNext fontSize='small' />
            <Skeleton variant='text' width={100} animation='wave' sx={{ display: 'inline-block' }} />
          </Typography>
        )}
      </Toolbar>
    </DrawerHeader>
  );
}

function Breadbar({ menu, servicioActivo }) {
  const location = useLocation();

  const servicio = menu.find((m) => m.ruta === servicioActivo);

  const rutasActual = (hijos) => {
    let rutas = [];

    for (const h of hijos) {
      if (!location.pathname.includes(h.ruta)) {
        continue;
      }

      rutas = [
        { ruta: h.ruta, nombre: h.nombre, actual: location.pathname.includes(h.ruta) },
        ...rutasActual(h.hijos)
      ];
    }

    return rutas;
  };

  return (
    <Breadcrumbs separator={<NavigateNext fontSize='small' />} sx={{ py: 1 }}>
      <TLink key={servicioActivo} to={servicio.hijos[0]?.ruta} style={{ color: 'secondary.main' }}>
        <Typography fontSize='0.87rem'>{servicio.nombre}</Typography>
      </TLink>
      {rutasActual(servicio.hijos).map((r) =>
        r.actual ? (
          <Typography key={r.ruta} fontSize='0.87rem' color='primary-alt.main'>
            {r.nombre}
          </Typography>
        ) : (
          <TLink key={r.ruta} to={r.ruta} style={{ color: 'secondary.main' }}>
            <Typography key={r.ruta} fontSize='0.87rem'>
              {r.nombre}
            </Typography>
          </TLink>
        )
      )}
    </Breadcrumbs>
  );
}
export default Navbar;
