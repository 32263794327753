/**
 * @module app/components/servicios/rv/comun/InfoBlock
 */

import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, InfoOutlined } from '@mui/icons-material';
import { Typography, Box, CircularProgress } from '@mui/material';

function InfoBlock({ tipo, children, variant, sx = null }) {
  let _icono;
  switch (tipo) {
    case 'error':
      _icono = <InfoOutlined sx={{ verticalAlign: 'middle', mr: 1, fill: '#D94B4B' }} />;
      break;
    case 'info':
      _icono = <ErrorOutlineOutlined sx={{ verticalAlign: 'middle', mr: 1, fill: '#D19233' }} />;
      break;
    case 'cargando':
      _icono = <CircularProgress variant='indeterminate' size={18} sx={{ verticalAlign: 'middle', mr: 1 }} />;
      break;
    case 'success':
      _icono = <CheckCircleOutlineOutlined sx={{ verticalAlign: 'middle', mr: 1, fill: '#65b78c;' }} />;
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        ...css.box,
        backgroundColor: variant ? '#f2f2f2' : tipo === 'success' ? '#e5f6ec' : '#FFF',
        boxShadow: variant ? 'none' : '0px 2px 11px 0px rgba(69, 81, 76, 0.14)',
        ...(sx && { ...sx })
      }}
    >
      <Typography>
        {_icono}
        <Typography
          component='span'
          color={tipo === 'error' ? '#D94B4B' : '#303030'}
          {...(tipo === 'success' && { fontWeight: 450 })}
        >
          {children}
        </Typography>
      </Typography>
    </Box>
  );
}

const css = {
  box: {
    padding: '12px 14px',
    borderRadius: '12px',
    '& span': { top: '1px', position: 'relative' }
  }
};

export default InfoBlock;
