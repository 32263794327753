/**
 * @module app/components/servicios
 */

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useMenu, useObtenerDatos } from '../../hooks';

import { Box, keyframes } from '@mui/material';
import styled from '@emotion/styled';

import Header from './Header';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { drawerWidth } from './utils';

import * as state from '../../state';
import * as api from '../../api';

const hideScroll = keyframes({ from: { overflowY: 'hidden' }, to: { overflowY: 'hidden' } });

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile'
})(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(!isMobile && {
    marginLeft: `-${drawerWidth}px`
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

const OutletContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile'
})(({ theme, open, isMobile }) => ({
  position: 'fixed',
  top: '80px',
  height: `calc(100% - 80px)`,
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
  padding: isMobile ? '0px 22px' : '0px 38px',
  width: open && !isMobile ? `calc(100% - ${drawerWidth}px)` : '100%',
  ...(!open && { animation: `${hideScroll} 1s backwards` }),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

/**
 * Layout pantalla de servicios
 * @returns {JSX.Element}
 */
function Servicios() {
  const isMobile = useSelector(state.config.isMobile);
  const servicioActivo = useSelector(state.config.servicioActivo);
  const [open, setOpen] = useState(!isMobile);

  const menu = useMenu();

  useObtenerDatos({
    nombre: 'procesos',
    llamadaAPI: api.rv.obtenerProcesos,
    defaultValue: []
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <Header
        open={open}
        abrir={() => {
          setOpen(true);
        }}
      />
      <Sidebar
        open={open}
        menu={menu}
        servicioActivo={servicioActivo}
        cerrar={() => {
          setOpen(false);
        }}
      />
      <Main open={open} isMobile={isMobile}>
        <Navbar menu={menu} servicioActivo={servicioActivo} />
        <OutletContainer
          component='div'
          open={open}
          isMobile={isMobile}
          style={{ backgroundColor: '#f2f2f2' }}
        >
          <Outlet />
        </OutletContainer>
      </Main>
    </Box>
  );
}

export default Servicios;
