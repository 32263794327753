import { red } from '@mui/material/colors';
import { esES as dataGridEsES } from '@mui/x-data-grid/locales';
import { esES as coreEsES } from '@mui/material/locale';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// muchos colores!
// https://maketintsandshades.com/
// https://colordesigner.io/

let theme = createTheme(
  {
    palette: {
      primary: {
        main: '#7bdea7',
        dark: '#3e6f54',
        light: '#bdefd3',
        S10: '#6fc896',
        S20: '#62b286',
        S30: '#569b75',
        S40: '#4a8564',
        S50: '#3e6f54',
        S60: '#315943',
        S70: '#254332',
        S80: '#192c21',
        S90: '#0c1611',
        T10: '#88e1b0',
        T20: '#95e5b9',
        T30: '#a3e8c1',
        T40: '#b0ebca',
        T50: '#bdefd3',
        T60: '#caf2dc',
        T70: '#d7f5e5',
        T80: '#e5f8ed',
        T90: '#f2fcf6'
      },
      'primary-alt': {
        main: '#1ab394',
        dark: '#0d5a4a',
        light: '#8dd9ca',
        S10: '#17a185',
        S20: '#158f76',
        S30: '#127d68',
        S40: '#106b59',
        S50: '#0d5a4a',
        S60: '#0a483b',
        S70: '#08362c',
        S80: '#05241e',
        S90: '#03120f',
        T10: '#31bb9f',
        T20: '#48c2a9',
        T30: '#5fcab4',
        T40: '#76d1bf',
        T50: '#8dd9ca',
        T60: '#a3e1d4',
        T70: '#bae8df',
        T80: '#d1f0ea',
        T90: '#e8f7f4'
      },
      secondary: {
        main: '#8e8279',
        dark: '#47413d',
        light: '#c7c1bc',
        S10: '#80756d',
        S20: '#726861',
        S30: '#635b55',
        S40: '#554e49',
        S50: '#47413d',
        S60: '#393430',
        S70: '#2b2724',
        S80: '#1c1a18',
        S90: '#0e0d0c',
        T10: '#998f86',
        T20: '#a59b94',
        T30: '#b0a8a1',
        T40: '#bbb4af',
        T50: '#c7c1bc',
        T60: '#d2cdc9',
        T70: '#dddad7',
        T80: '#e8e6e4',
        T90: '#f4f3f2'
      },
      terciary: {
        main: '#2F4050',
        dark: '#182028',
        light: '#97a0a8',
        S10: '#2a3a48',
        S20: '#263340',
        S30: '#212d38',
        S40: '#1c2630',
        S50: '#182028',
        S60: '#131a20',
        S70: '#0e1318',
        S80: '#090d10',
        S90: '#050608',
        T10: '#445362',
        T20: '#596673',
        T30: '#6d7985',
        T40: '#828c96',
        T50: '#97a0a8',
        T60: '#acb3b9',
        T70: '#c1c6cb',
        T80: '#d5d9dc',
        T90: '#eaecee'
      },
      error: {
        main: red.A400
      },
      tentativo: {
        grey: '#989898',
        lightGreen: '#e5f6ec',
        activeGreen: '#77dda7',
        btnGreen: '#65b78c',
        menuText: '#202521',
        textGreen: '#0B1F14'
      }
    },
    typography: {
      fontFamily: 'Poppins',
      button: {
        textTransform: 'none'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          *{
            font-family:'Poppins'
          }
        `
      }
    }
  },
  dataGridEsES,
  coreEsES
);

theme = responsiveFontSizes(theme);

export default theme;
