function TooltipGrafica({ indexValue, id, formattedValue, color, width = null, cuadro = null }) {
  return (
    <div style={{ ...css.composicionTooltip, ...(width && { width: '100%' }) }}>
      {cuadro && <span style={{ backgroundColor: cuadro, ...css.leyenda }}></span>}
      {'  '}
      {indexValue} —{' '}
      {id ? (
        <>
          <span style={{ backgroundColor: color, ...css.tooltipSpan }}></span> {id} —
        </>
      ) : (
        ''
      )}{' '}
      <b>{formattedValue}</b>
    </div>
  );
}

const css = {
  leyenda: {
    height: '12px',
    width: '12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '2px'
  },
  composicionTooltip: {
    display: 'inline-block',
    backgroundColor: '#FFF',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
    padding: '5px 9px',
    borderRadius: '2px',
    fontSize: 'inherit',
    color: 'inherit'
  },
  tooltipSpan: {
    height: '12px',
    width: '12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '2px'
  }
};

export default TooltipGrafica;
