/**
 * @module app/components/thallus/TLink
 */

import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const css = {
  color: 'terciary.dark',
  ':hover': {
    color: 'primary-alt.main'
  }
};

/** *
 * @param {object} params
 * @param {object} params.style
 * @param {string} params.to
 * @param {(JSX.Element|string)} params.children
 * @returns {JSX.Element}
 */
function TLink({ to, children, onClick = null, style = null }) {
  return (
    <Link
      component={RouterLink}
      sx={{ ...css, ...(style && style) }}
      to={to}
      {...(onClick && { onClick })}
    >
      {children}
    </Link>
  );
}

export default TLink;
