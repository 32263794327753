import { StrictMode } from 'react';

// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { injectDeviceHashToAxiosInstances } from './app/api/_instancias';
import store from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

// TODO: Remover estas variantes más adelante en favor de Poppins por decisión administrativa.
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
// import { deviceHash } from './utils';

// TODO: implementar MUI Grid v2 cuando salga de unstable

// Sentry.init({
//   dsn: 'https://c395a1ac35344f009955c0c932b49d2f@o229249.ingest.sentry.io/6655571',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
//   enabled: process.env.NODE_ENV === 'production'
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 1000 * 60 * 5
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

injectDeviceHashToAxiosInstances(store);

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
