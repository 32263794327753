import { configureStore } from '@reduxjs/toolkit';

import config from './state/config';
import dataUsuario from './state/dataUsuario';

const store = configureStore({
  reducer: {
    config,
    dataUsuario
  }
});

export default store;
