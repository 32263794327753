import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Fragment, useState } from 'react';

function Ranking({ datos }) {
  const [conjunto, setConjunto] = useState('Todas');

  if (!datos) return;

  const cabecera = (
    <>
      <Box sx={css.cardHeader}>
        <h2>
          Ranking {!Array.isArray(datos) && datos?.inDev ? '(EN DESARROLLO)' : ''}
          {/* TODO: Añadir país a este texto */}
        </h2>
      </Box>
      <Box>
        <FormControl sx={{ my: 1, minWidth: 120 }} size='small'>
          <Select value={conjunto} onChange={(val) => setConjunto(val.target.value)} sx={css.select}>
            {Object.keys(datos.sets).map((set) => (
              <MenuItem key={set.trim()} value={set} sx={css.menuitem}>
                {set === 'Todas' ? 'Posición' : set}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );

  return (
    <>
      {cabecera}
      <Box sx={{ ...css.colab, opacity: 0.5 }}>
        <span>N°</span>
        <span style={{ padding: '0 2px' }}>Trabajador</span>
        <span style={{ flexGrow: 1, textAlign: 'right' }}>RV Total</span>
      </Box>
      <Box sx={{ overflow: 'auto', height: 'calc(100% - 73px)' }}>
        {datos?.sets[conjunto].map((colab, i) => (
          <Fragment key={`ranking_${i}`}>
            <Box sx={css.colab} key={i}>
              <span>{i + 1}°</span>
              <Box sx={css.colabDatos}>
                <h5>{colab.nombre}</h5>
                <h6>{colab.posicion}</h6>
              </Box>
              <div style={{ flexGrow: 1 }}>
                <Typography sx={css.colabRV}>{parseInt(colab.rv_total).toLocaleString('es-CL')}</Typography>
              </div>
            </Box>
            <hr style={{ color: '#FFF', opacity: 0.5 }} />
          </Fragment>
        ))}
      </Box>
    </>
  );
}

const css = {
  card: {
    borderRadius: '16px',
    padding: '16px',
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  cardHeader: {
    '& h2': {
      fontSize: { xs: 17, lg: 20 },
      fontWeight: 600,
      margin: 0,
      padding: 0
    }
  },
  sectionDesc: {
    fontSize: 14,
    fontWeight: 400
  },
  colab: {
    display: 'flex',
    alignItems: 'flex-start',
    '& span': { fontSize: 14 }
  },
  colabDatos: {
    px: 1,
    '& *': { padding: 0, margin: 0 },
    '& h5': { fontSize: '14px', fontWeight: 500 },
    '& h6': { fontSize: '12px', fontWeight: 300 }
  },
  colabRV: {
    margin: 0,
    padding: 0,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 500
  },
  select: {
    fontSize: 14,
    backgroundColor: '#e8e8e8',
    borderRadius: '16px',
    '& fieldset': {
      border: 0
    },
    '& .MuiSelect-select': {
      padding: '2px 16px'
    }
  },
  menuitem: {
    '&:hover': { backgroundColor: '#f5fcf8' }
  }
};

export default Ranking;
