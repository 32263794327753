import { useState } from 'react';
import { useObtenerDatos, useProcesos } from '../../../hooks';

import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';

import TablaResultados from './resultados/TablaResultados';
import SelectorProcesos from './comun/SelectorProceso';
import SelectorGrupoModelo from './comun/SelectorGrupoModelo';
import InfoBlock from './comun/InfoBlock';

import * as api from '../../../api';

const estados = ['CERRADO'];

function Maestro() {
  const { grupoModelo, gruposModelos, proceso } = useProcesos({ estados });

  let errorProceso = { errId: 1, razon: 'Cargando Maestro ...', tipo: 'cargando' };

  const [descargando, setDescargando] = useState(false);

  const { [`maestro_proceso_${proceso?.valor?.codigo}`]: maestroProceso } = useObtenerDatos({
    nombre: `maestro_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerMaestro,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  const onCambioProceso = (codigo) => {
    proceso.setProceso(grupoModelo.procesos.find((p) => p.codigo === codigo));
  };

  const onCambioGrupo = (grupo) => {
    proceso.setProceso(null);
    grupoModelo.setGrupoModelo(grupo);
  };

  function onDescargaMaestro() {
    setDescargando(true);
    api.rv
      .descargarMaestro({ proceso: proceso?.valor?.codigo })
      .then((respuesta) => respuesta.data)
      .then((data) => {
        const enlace = `data:${data.type};base64,${data.file}`;

        const _a = document.createElement('a');
        _a.href = enlace;
        _a.setAttribute('download', `${data.filename}.${data.ext}`);
        _a.click();
        setDescargando(false);
      });
  }

  if (!!grupoModelo?.valor) {
    if (grupoModelo?.procesos?.length === 0) {
      errorProceso = {
        errId: -1,
        razon: 'No hay procesos cerrados disponibles',
        tipo: 'info'
      };
    }

    if (!!proceso?.valor) {
      if (proceso.valor.estado !== 'CERRADO') {
        errorProceso = { errId: 10, razon: `Proceso no se ha cerrado aún.`, tipo: 'info' };
      } else if (!maestroProceso) {
        errorProceso = { errId: 1, razon: 'Cargando Maestro ...', tipo: 'cargando' };
      } else {
        errorProceso = { errId: 0 };
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          py: '20px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        <Typography sx={{ ...css.h1 }} component={'span'}>
          Maestro Renta Variable
          {gruposModelos?.length > 1 && (
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
          )}
          {grupoModelo?.procesos?.length > 0 && (
            <SelectorProcesos
              onCambioProceso={onCambioProceso}
              proceso={proceso?.valor?.codigo}
              procesos={grupoModelo.procesos}
            />
          )}
        </Typography>

        <Box sx={{ flexGrow: 1, textAlign: 'right', mt: 1 }}>
          {/* <Button
            variant='contained'
            sx={{ ...css.subActionBtn }}
            size='large'
            startIcon={<SearchOutlinedIcon />}
            onClick={() => {}}
            disabled={!['CERRADO'].includes(estadoProceso) || errorProceso.errId !== 0}
          >
            Notificar a Proveedores
          </Button> */}
          <Button
            variant='contained'
            sx={{ ...css.actionBtn }}
            onClick={onDescargaMaestro}
            size='large'
            disabled={
              !['CERRADO'].includes(proceso?.valor?.estado) || errorProceso.errId !== 0 || descargando
            }
          >
            {descargando && <CircularProgress size={18} sx={{ mr: 1, color: '#539170' }} />} Descargar Maestro
          </Button>
        </Box>
      </Box>
      {errorProceso.errId ? (
        <InfoBlock tipo={errorProceso.tipo}>{errorProceso.razon}</InfoBlock>
      ) : (
        <Paper sx={css.card}>
          <TablaResultados columnas={maestroProceso.columnas} filas={maestroProceso.filas} />
        </Paper>
      )}
    </>
  );
}
const css = {
  card: {
    borderRadius: '16px',
    marginBottom: '20px',
    padding: '16px'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  actionBtn: {
    boxShadow: 'none',
    backgroundColor: 'tentativo.btnGreen',
    color: 'white',
    borderRadius: 20,
    fontSize: { xs: 14, lg: 17 },
    padding: { xs: '6px 20px', lg: 'inherited' }
  },
  subActionBtn: {
    boxShadow: 'none',
    backgroundColor: '#cee6d8',
    color: 'tentativo.menuText',
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 500,
    padding: '6px 20px'
  }
};

export default Maestro;
