import { CircularProgress } from '@mui/material';

import { useObtenerDatos } from '../../hooks';

import TSelect from './TSelect';

import * as api from '../../api';

function TSelectInstrumentos({ onChange }) {
  const { instrumentos, cargando } = useObtenerDatos({
    nombre: 'instrumentos',
    llamadaAPI: api.nc.obtenerInstrumentosActivos
  });

  return cargando ? (
    <CircularProgress />
  ) : (
    <TSelect
      id='instrumentos'
      label='Instrumentos'
      placeholder='Instrumentos'
      opciones={instrumentos}
      onChange={onChange}
    />
  );
}

export default TSelectInstrumentos;
