/**
 * @module app/components/thallus/TFormularioNuevaPassword
 */

import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useObtenerDatos } from '../../hooks';

import { Box, Stack, Link, CircularProgress } from '@mui/material';
import InfoBlock from '../servicios/rv/comun/InfoBlock';
import TBotonSubmit from './TBotonSubmit';
import TInputPassword from './TInputPassword';
import TPasswordIndicator from './TPasswordIndicator';

import * as api from '../../api';

const evaluaciones = [
  // { error: 'La contraseña no puede quedar vacía', evaluacion: (valor) => valor.length === 0 },
  {
    error: 'La contraseña no puede contener espacios',
    evaluacion: (valor) => /\s/.test(valor)
  },
  {
    error: 'La contraseña debe tener al menos 8 caracteres',
    evaluacion: (valor) => valor.length < 8
  },
  {
    error: 'La contraseñana debe contener letras mayúsculas, minúsculas y números',
    evaluacion: (valor) =>
      !(/^([a-zA-Z0-9]+)$/.test(valor) && /\d/.test(valor) && /[A-Z]/.test(valor) && /[a-z]/.test(valor))
  }
];

/**
 * Formulario de nueva contraseña (recuperar)
 * @returns {JSX.Element}
 */
function TFormularioNuevaPassword({ token }) {
  const navigate = useNavigate();

  const [formulario, setFormulario] = useState({
    'th-password': '',
    'th-re-password': '',
    'th-token': ''
  });
  const [errores, setErrores] = useState({
    'th-password': [],
    'th-re-password': null
  });

  const [datos, setDatos] = useState(null);

  const { cargando, isSuccess, isError } = useObtenerDatos({
    nombre: 'restablecerPassword',
    llamadaAPI: api.autenticacion.restablecerPassword,
    parametros: { datos },
    condicion: !!datos,
    nofresh: true,
    usaToken: false
  });

  const handleOnLoginSubmit = (event) => {
    event.preventDefault();

    setErrores({
      'th-password': [],
      'th-re-password': null
    });

    let err = { 'th-password': [] };

    for (const ev of evaluaciones) {
      if (ev.evaluacion(formulario['th-password'])) {
        err['th-password'].push(ev.error);
      }
    }

    if (err['th-password'].length === 0) {
      err['th-re-password'] =
        formulario['th-password'] !== formulario['th-re-password'] ? 'Las contraseñas no coinciden' : null;
    }

    setErrores({ ...err });

    if (err['th-password'].length === 0 && !err['th-re-password']) {
      setDatos({ password: formulario['th-password'], token: token });
    }
  };

  const handleChange = (prop) => (event) => {
    setFormulario({ ...formulario, [prop]: event.target.value });
  };

  const onLinkClick = (evt) => {
    navigate('/autenticacion/ingresar', { replace: true });
  };

  useEffect(() => {
    if (isError) {
      setDatos(null);
    }
  }, [isError]);

  return isSuccess ? (
    <InfoBlock tipo='success'>
      <span style={{ fontSize: '0.9rem' }}>
        Se ha guardado tu nueva contraseña, ya puedes{' '}
        <Link style={{ color: '#539170', fontWeight: 600, cursor: 'pointer' }} onClick={onLinkClick}>
          Ingresar
        </Link>{' '}
        a la plataforma.
      </span>
    </InfoBlock>
  ) : (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ marginTop: 2.5 }}
      onSubmit={handleOnLoginSubmit}
    >
      <TInputPassword
        value={formulario['th-password']}
        onChange={handleChange('th-password')}
        error={
          errores['th-password'].length > 0 &&
          errores['th-password'].map((e, i) => (
            <Fragment key={i}>
              {e}
              <br />
            </Fragment>
          ))
        }
        variant='outlined'
      />
      <TInputPassword
        value={formulario['th-re-password']}
        onChange={handleChange('th-re-password')}
        error={errores['th-re-password']}
        variant='outlined'
        repeat={true}
      />
      {/* <TPasswordIndicator contra={formulario['th-password']} /> */}
      <div style={{ textAlign: 'center', marginTop: 19 }}>
        <TBotonSubmit>
          {cargando ? <CircularProgress size={13} color='terciary' /> : 'Crear mi contraseña'}
        </TBotonSubmit>
      </div>
    </Box>
  );
}

export default TFormularioNuevaPassword;
