import { FormControl, MenuItem, Select } from '@mui/material';

/**
 * @module app/components/servicios/rv/comun/SelectorProcesos
 */
function SelectorProcesos({ proceso, onCambioProceso, procesos }) {
  return (
    <FormControl sx={{ minWidth: 80 }}>
      <Select
        sx={css.select}
        autoWidth
        labelId='SelectorProcesos'
        value={proceso ?? ''}
        onChange={(evt) => onCambioProceso(evt.target.value)}
      >
        {procesos.map((proc) => (
          <MenuItem key={`selector_${proc.codigo}`} value={proc.codigo} sx={css.menuitem}>
            {proc.nombre.toLowerCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const css = {
  select: {
    borderBottom: '3px solid #539170',
    borderRadius: 2,
    '&:hover': { backgroundColor: '#FFF' },
    '& fieldset': {
      border: 0
    },
    '& .MuiSelect-select': {
      fontSize: { xs: 24, lg: 32 },
      padding: 0,
      verticalAlign: 'middle',
      textTransform: 'capitalize',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      fontWeight: 600,
      marginLeft: 1
    }
  },
  menuitem: {
    '&:hover': { backgroundColor: '#f5fcf8' }
  }
};

export default SelectorProcesos;
