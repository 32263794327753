import useObtenerDatos from './useObtenerDatos';

import * as api from '../api';

function useUsuario() {
  const { usuario, cargando } = useObtenerDatos({
    nombre: 'usuario',
    llamadaAPI: api.autenticacion.infoUsuario
  });

  return { usuario, cargando };
}

export default useUsuario;
