/**
 * @module app/components/autenticacion
 */
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Grid } from '@mui/material';
import { grey } from '@mui/material/colors';

import * as storage from '../../storage';

/**
 * Layout pantalla de autenticación
 * @returns {JSX.Element}
 */
function Autenticacion() {
  useEffect(() => {
    storage.token.remover();
  }, []);

  return (
    <Grid container direction='row' alignItems='stretch' sx={{ minHeight: '100vh', display: 'grid', gap: 0 }}>
      <Grid container item sx={{ gridColumn: 1, gridRow: 'span 19' }}>
        <Outlet />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{ gridColumn: 1, gridRow: '20 / 20', backgroundColor: grey[200] }}
      ></Grid>
    </Grid>
  );
}

export default Autenticacion;
