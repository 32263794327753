/**
 * @module app/components/autenticacion/TAuthCard
 */
import { Box } from '@mui/material';
import Logo from './Logo';

const css = {
  formCard: {
    width: 510,
    maxWidth: '100vw',
    padding: '0 4rem',
    borderRadius: '2.5rem',
    backgroundColor: 'rgba(248, 253, 250, 0.75)',
    height: '700px',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
};

/**
 * Información en pantalla de autenticación
 * @returns {JSX.Element}
 */
function TAuthCard({ children }) {
  return <Box sx={{ ...css.formCard }}>{children}</Box>;
}

export default TAuthCard;
