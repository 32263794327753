/**
 * @module app/components/thallus/TFormularioRestablecerPassword
 */

import { useEffect, useState } from 'react';
import { useObtenerDatos } from '../../hooks';
import { Box, CircularProgress } from '@mui/material';

import TBotonSubmit from './TBotonSubmit';
import TInputTexto from '../thallus/TInputTexto';

import * as api from '../../api';
import InfoBlock from '../servicios/rv/comun/InfoBlock';

/**
 * Formulario recuperación de password
 * @returns {JSX.Element}
 */
function TFormularioRestablecerPassword() {
  const [formulario, setFormulario] = useState({
    'th-correo': '',
    'th-empresa': ''
  });

  const [errores, setErrores] = useState({
    'th-correo': null,
    'th-empresa': null
  });

  const [datos, setDatos] = useState(null);

  const { cargando, isSuccess, isError } = useObtenerDatos({
    nombre: 'recuperarPassword',
    llamadaAPI: api.autenticacion.solictarRestablecerPassword,
    parametros: { datos },
    condicion: !!datos,
    nofresh: true,
    usaToken: false
  });

  const handleOnLoginSubmit = (event) => {
    event.preventDefault();

    setErrores({
      'th-correo': null,
      'th-empresa': null
    });

    let recuperacionDatos = {};
    let err = {};

    for (let k in formulario) {
      if (k.startsWith('th-')) {
        if (formulario[k] === '') {
          err[k] = 'Campo requerido.';
          // } else if (k === 'th-correo' && formulario[k] !== '' && !formulario[k].includes('@')) {
          //   err[k] = 'Dirección de correo no válida.';
        } else {
          recuperacionDatos[k.slice(3)] = formulario[k];
          err[k] = null;
        }
      }
    }

    setErrores({ ...err });

    if (Object.values(err).filter((e) => e).length === 0) {
      setDatos(recuperacionDatos);
    }
  };

  const handleChange = (prop) => (event) => {
    setFormulario({ ...formulario, [prop]: event.target.value });
  };

  useEffect(() => {
    if (isError) {
      setDatos(null);
    }
  }, [isError]);

  return isSuccess ? (
    <InfoBlock tipo='success'>
      <span style={{ fontSize: '0.9rem' }}>
        Se ha solicitado restablecer tu contraseña, revisa tu correo para completar el proceso.
      </span>
    </InfoBlock>
  ) : (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ marginTop: 2.5 }}
      onSubmit={handleOnLoginSubmit}
    >
      <TInputTexto
        id='th-empresa'
        value={formulario.empresa}
        onChange={handleChange('th-empresa')}
        required
        variant='outlined'
        label='Empresa'
        placeholder='empresa'
        error={errores['th-empresa']}
      />
      <TInputTexto
        id='th-correo'
        value={formulario.correo}
        onChange={handleChange('th-correo')}
        required
        variant='outlined'
        label='Correo Corporativo'
        placeholder='Ejemplo@thallus.cl'
        error={errores['th-correo']}
      />
      <div style={{ textAlign: 'center', marginTop: 19 }}>
        <TBotonSubmit>
          {cargando ? <CircularProgress size={13} color='terciary' /> : 'Enviar enlace de restablecimiento'}
        </TBotonSubmit>
      </div>
    </Box>
  );
}

export default TFormularioRestablecerPassword;
