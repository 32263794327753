import { cloneElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {
  ArrowDropDown,
  ArrowRight,
  DashboardOutlined,
  FileUploadOutlined,
  AssessmentOutlined,
  ContactPageOutlined,
  SettingsOutlined,
  MenuOutlined,
  AddModeratorOutlined,
  AdminPanelSettingsOutlined,
  SourceOutlined,
  RuleFolderOutlined
} from '@mui/icons-material';

const ICONOS = {
  DashboardOutlined,
  FileUploadOutlined,
  AssessmentOutlined,
  ContactPageOutlined,
  SettingsOutlined,
  MenuOutlined,
  AddModeratorOutlined,
  AdminPanelSettingsOutlined,
  SourceOutlined,
  RuleFolderOutlined
};

function SidebartItem({ item, sidebarOpen, hijo = false, onClick = null }) {
  const location = useLocation();

  const Icono = ICONOS[item.icono];

  return (
    <ListItem disablePadding sx={{ display: 'block', backgroundColor: 'transparent' }}>
      <ListItemButton
        dense
        component={RouterLink}
        to={item.ruta}
        relative='path'
        sx={{
          paddingLeft: '2px',
          paddingRight: '8px',
          borderRadius: '10px',
          ...(location.pathname.includes(item.ruta) &&
            !item.codigo && {
              backgroundColor: 'tentativo.lightGreen'
            }),
          ...(location.pathname.includes(item.ruta) &&
            item.codigo && {
              backgroundColor: 'tentativo.activeGreen',
              '&:hover': { backgroundColor: 'tentativo.activeGreen' }
            }),
          ...(hijo && { marginY: 0.375 })
        }}
      >
        {!item.codigo &&
          (location.pathname.includes(item.ruta) ? (
            <ArrowDropDown sx={{ color: 'tentativo.menuText' }} />
          ) : (
            <ArrowRight sx={{ color: 'tentativo.menuText' }} />
          ))}

        {Icono && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sidebarOpen ? 1 : 'auto',
              justifyContent: 'center',
              ...(item.codigo && { marginLeft: '24px' })
            }}
          >
            {cloneElement(<Icono />, {
              sx: {
                color: 'tentativo.menuText'
              }
            })}
          </ListItemIcon>
        )}
        <ListItemText
          primary={item.nombre}
          disableTypography
          sx={{
            fontSize: 14,
            opacity: sidebarOpen ? 1 : 0,
            ...(location.pathname === item.ruta && {
              color: 'tentativo.menuText',
              fontWeight: '600'
            }),
            ...(hijo && { paddingLeft: 2 })
          }}
        />
      </ListItemButton>
      {!item.codigo && (
        <Collapse
          in={location.pathname === item.ruta || location.pathname.includes(item.ruta)}
          timeout='auto'
          unmountOnExit
        >
          <List component='ul' disablePadding sx={{ pl: 4 }}>
            {item.hijos
              .filter((item) => !!item.componente)
              .map((item) => (
                <SidebartItem hijo key={item.codigo || item.ruta} item={item} sidebarOpen={sidebarOpen} />
              ))}
            <div
              style={{
                position: 'absolute',
                left: 18,
                top: 6,
                bottom: 6,
                width: '2px',
                backgroundColor: '#e5f6ec'
              }}
            ></div>
          </List>
        </Collapse>
      )}
    </ListItem>
  );
}

export default SidebartItem;
