import { createSlice } from '@reduxjs/toolkit';

const initialState = { deviceHash: null, ip: null };

export const dataUsuarioSlice = createSlice({
  name: 'dataUsuario',
  initialState,
  reducers: {
    guardarDeviceHash: (state, action) => {
      state.deviceHash = action.payload;
    },
    guardarIP: (state, action) => {
      state.ip = action.payload;
    }
  }
});

export const { guardarDeviceHash, guardarIP } = dataUsuarioSlice.actions;

export const deviceHashGuardado = (state) => state.dataUsuario.deviceHash !== null;
export const ipGuardada = (state) => state.dataUsuario.ip !== null;

export default dataUsuarioSlice.reducer;
