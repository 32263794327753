/**
 * Llamadas a api de autorización
 * @module app/api/autorizacion
 */

import { autorizacion } from './_instancias';

/**
 * Obtener permisos de usuario
 * @async
 * @param {object} params
 * @param {string} params.token
 * @returns {object}
 */
const permisos = async () => (await autorizacion.get('/permisos/menus/usuario')).data;

const guardarPermisos = async ({ permisos }) => (await autorizacion.post('/permisos', { permisos })).data;

const obtenerPermisos = async () => (await autorizacion.get('/permisos')).data;

const obtenerRecursos = async () => (await autorizacion.get('/recursos')).data;

const crearRecurso = async ({ recurso }) => (await autorizacion.post('/recursos', recurso)).data;

const editarRecurso = async ({ recurso }) => await autorizacion.put('/recursos', recurso);

const asignarPermisosMenu = async ({ menu, permisos }) =>
  (await autorizacion.post('/permisos/menu/' + menu, { permisos })).data;

const asignarPermisosUsuario = async ({ usuario, permisos }) =>
  (await autorizacion.post('/permisos/usuario/' + usuario, { permisos })).data;

const obtenerAcciones = async () => (await autorizacion.get('/acciones')).data;

const obtenerMenusPermisos = async () => (await autorizacion.get('/permisos/menu')).data;

export {
  permisos,
  obtenerPermisos,
  asignarPermisosMenu,
  obtenerRecursos,
  crearRecurso,
  editarRecurso,
  asignarPermisosUsuario,
  obtenerAcciones,
  guardarPermisos,
  obtenerMenusPermisos
};
