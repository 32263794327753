import { useAccion, useObtenerDatos, useProcesos } from '../../../hooks';
import { useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, Grid, Paper, Typography, Link } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import TablaResultados from './resultados/TablaResultados';
import SelectorProcesos from './comun/SelectorProceso';
import SelectorGrupoModelo from './comun/SelectorGrupoModelo';
import InfoBlock from './comun/InfoBlock';
import TooltipGrafica from './panel_control/TooltipGrafica';

import * as api from '../../../api';

function ControlResultados() {
  const graficaRef = useRef();

  const navigate = useNavigate();

  const [descargando, setDescargando] = useState(false);
  const [maestroGenerado, setMaestroGenerado] = useState(null);

  const { grupoModelo, gruposModelos, proceso } = useProcesos();

  // obtener como configuracion del sistema
  const colores = ['#7BDEA7', '#0B4F6C', '#056DFA', '#F9B673'];

  let errorProceso = { errId: 0, razon: null, tipo: 'cargando' };

  const { [`resultados_ind_proc_${proceso?.valor?.codigo}`]: resultadosIndicadores } = useObtenerDatos({
    nombre: `resultados_ind_proc_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerResIndicadores,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: ['CERRADO', 'PROCESADO'].includes(proceso?.valor?.estado) && !!proceso?.valor
  });

  const { [`resultados_proceso_${proceso?.valor?.codigo}`]: resultadosIndividuales } = useObtenerDatos({
    nombre: `resultados_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerResIndividuales,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: ['CERRADO', 'PROCESADO'].includes(proceso?.valor?.estado) && !!proceso?.valor
  });

  const { [`resultados_gen_proc_${proceso?.valor?.codigo}`]: resultadosGenerales } = useObtenerDatos({
    nombre: `resultados_gen_proc_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerResGenerales,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: ['CERRADO', 'PROCESADO'].includes(proceso?.valor?.estado) && !!proceso?.valor
  });

  const { [`resultados_evolucion_rentas_${proceso?.valor?.codigo}`]: resultadosEvolucion } = useObtenerDatos({
    nombre: `resultados_evolucion_rentas_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerResEvolucion,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: ['CERRADO', 'PROCESADO'].includes(proceso?.valor?.estado) && !!proceso?.valor
  });

  const { accionar, procesando, procesado } = useAccion({
    llamadaAPI: api.rv.cerrarProceso,
    cacheActualizar: ['procesos']
  });

  const rangoEvol = useMemo(() => {
    if (!!resultadosEvolucion) {
      const bruto = resultadosEvolucion.map((cargo) => cargo.evolucion.map((mes) => mes.total)).flat();

      const min = Math.min(...bruto);
      const max = Math.max(...bruto);

      return {
        min: min - max / 6,
        max: max + max / 6
      };
    }

    return { min: 0, max: 0 };
  }, [resultadosEvolucion]);

  const onCambioProceso = (codigo) => {
    proceso.setProceso(grupoModelo.procesos.find((p) => p.codigo === codigo));
  };

  const onCambioGrupo = (grupo) => {
    grupoModelo.setGrupoModelo(grupo);
  };

  function onDescargaResultadosIndividuales() {
    setDescargando(true);
    api.rv
      .descargarResIndividuales({ proceso: proceso?.valor?.codigo })
      .then((respuesta) => respuesta.data)
      .then((data) => {
        const enlace = `data:${data.type};base64,${data.file}`;

        const _a = document.createElement('a');
        _a.href = enlace;
        _a.setAttribute('download', `${data.filename}.${data.ext}`);
        _a.click();
        setDescargando(false);
      });
  }

  const handleClick = (evt) => {
    accionar({ proceso: proceso?.valor?.codigo });
    setMaestroGenerado(proceso?.valor?.codigo);
  };

  const onVerMaestroClick = (evt) => {
    navigate('/servicios/rv/resultados/maestro', {
      replace: true,
      state: { contexto: { grupoModelo: grupoModelo?.valor, proceso: proceso?.valor?.codigo } }
    });
  };

  if (!!proceso?.valor && !['CERRADO', 'PROCESADO'].includes(proceso?.valor?.estado)) {
    errorProceso = { errId: 10, razon: `Proceso no se ha calculado aún.`, tipo: 'info' };
  } else {
    if (
      !proceso?.valor ||
      !resultadosGenerales ||
      !resultadosIndividuales ||
      !resultadosIndicadores ||
      !resultadosEvolucion
    ) {
      errorProceso = { errId: 1, razon: 'Cargando Resultados ...', tipo: 'cargando' };
    }
  }

  return (
    <>
      <Box sx={{ py: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Typography sx={{ ...css.h1 }} component={'span'}>
          Control de Resultados
        </Typography>
        {grupoModelo?.procesos?.length > 0 && (
          <div>
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
            <SelectorProcesos
              onCambioProceso={onCambioProceso}
              proceso={proceso?.valor?.codigo}
              procesos={grupoModelo.procesos}
            />
          </div>
        )}
        {proceso?.valor?.estado === 'PROCESADO' && (
          <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
            <Button
              variant='contained'
              sx={{ ...css.actionBtn }}
              disabled={procesando}
              onClick={procesando ? undefined : handleClick}
              size='large'
            >
              {procesando ? (
                <>
                  <Typography>
                    Espere un minuto. <b>No cierre la pestaña.</b>
                  </Typography>
                  <CircularProgress size={18} sx={{ ml: 1, color: '#539170' }} />
                </>
              ) : (
                <>Generar Maestro</>
              )}
            </Button>
          </Box>
        )}
      </Box>

      {procesado && maestroGenerado === proceso?.valor?.codigo && (
        <Box sx={{ mb: 1 }}>
          <InfoBlock tipo='success' sx={{ marginBottom: 1 }}>
            <span>
              Maestro generado correctamente.{' '}
              <Link
                style={{ color: '#539170', fontWeight: 600, cursor: 'pointer' }}
                onClick={onVerMaestroClick}
              >
                Ver Maestro
              </Link>
              .
            </span>
          </InfoBlock>
        </Box>
      )}
      {errorProceso.errId ? (
        <InfoBlock tipo={errorProceso.tipo}>{errorProceso.razon}</InfoBlock>
      ) : (
        <>
          <Paper sx={css.card}>
            {!Array.isArray(resultadosIndicadores) ? (
              <>
                <Grid container spacing={2}>
                  <Grid item lg={12} xl={7}>
                    <Typography sx={{ ...css.h2 }}>Indicadores</Typography>
                    <Box sx={css.contenedorTabla}>
                      <TablaResultados
                        columnas={resultadosIndicadores.columnas}
                        filas={resultadosIndicadores.filas}
                        toolbar={false}
                        paginacion={false}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} xl={5}>
                    <Typography sx={{ ...css.h2, textAlign: 'center', fontSize: 16 }}>
                      Evolución Renta Variable
                    </Typography>
                    <Box sx={{ height: '200px' }} ref={graficaRef}>
                      <ResponsiveLine
                        data={resultadosEvolucion.map((cargo, i) => {
                          return {
                            id: cargo.cargo,
                            color: colores[i],
                            data: cargo.evolucion.map((mes) => {
                              return { x: mes.mes, y: Math.round(mes.total) };
                            })
                          };
                        })}
                        margin={{
                          top: 10,
                          right: 15,
                          bottom: graficaRef?.current?.offsetWidth > 330 ? 25 : 5,
                          left: 50
                        }}
                        xScale={{ type: 'point' }}
                        yScale={{
                          type: 'linear',
                          min: rangoEvol.min >= 0 ? rangoEvol.min : 0,
                          max: rangoEvol.max
                        }}
                        colors={colores}
                        axisLeft={{
                          format: (tick) => (tick / 1_000_000).toLocaleString('es-CL'),
                          tickValues: 6,
                          legend: 'M$',
                          legendPosition: 'middle',
                          legendOffset: -40
                        }}
                        lineWidth={6}
                        pointSize={6}
                        pointBorderColor={{ from: 'serieColor' }}
                        enableGridX={false}
                        enableGridY={false}
                        theme={{ fontFamily: 'Poppins', background: 'transparent' }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={
                          graficaRef?.current?.offsetWidth > 330
                            ? { legend: null }
                            : { legend: null, renderTick: () => null }
                        }
                        useMesh={true}
                        yFormat={(valor) => `$ ${valor.toLocaleString('es-CL')}`}
                        valueFormat={(valor) => `$ ${Math.round(valor).toLocaleString('es-CL')}`}
                        tooltip={({ point }) => (
                          <TooltipGrafica
                            indexValue={point.serieId + ' — ' + point.data.x}
                            formattedValue={point.data.yFormatted}
                            width
                            cuadro={point.color}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={css.cajaLeyenda}>
                      {' '}
                      {resultadosEvolucion.map((cargo, i) => (
                        <div key={i} style={{ fontSize: 14 }}>
                          <span style={{ backgroundColor: colores[i], ...css.leyenda }}></span>
                          {cargo.cargo}
                        </div>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Box sx={{ ...css.cardHeader }}>
                <Typography sx={css.h2} className='cardHeader'>
                  Indicadores
                </Typography>
              </Box>
            )}
          </Paper>
          <Paper sx={css.card}>
            {!Array.isArray(resultadosGenerales) ? (
              <TablaResultados
                titulo={'Resultados Generales' + (resultadosGenerales?.inDev ? ' (EN DESARROLLO)' : '')}
                columnas={resultadosGenerales.columnas}
                filas={resultadosGenerales.filas}
                idContador='var'
                toolbar={false}
                paginacion={false}
              >
                La tabla siguiente te permitirá en una vista, verificar posibles errores en el ingreso de
                datos para el mes en proceso al comparar desviación respecto al mes anterior y otros
                estadígrafos relevantes
              </TablaResultados>
            ) : (
              <Box sx={css.cardHeader}>
                <Typography sx={css.h2} className='cardHeader'>
                  Resultados Generales
                </Typography>
              </Box>
            )}
          </Paper>
          <Paper sx={css.card}>
            {!Array.isArray(resultadosIndividuales) ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={css.h2} className='cardHeader'>
                      Resultados Individuales
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display='flex' justifyContent='flex-end'>
                    <Button
                      variant='contained'
                      sx={{ ...css.actionBtn, ...css.actionBtnTabla }}
                      onClick={onDescargaResultadosIndividuales}
                      size='large'
                      // disabled={
                      //   !['CERRADO'].includes(proceso?.valor?.estado) || errorProceso.errId !== 0 || descargando
                      // }
                    >
                      {descargando && <CircularProgress size={18} sx={{ mr: 1, color: '#539170' }} />}{' '}
                      Descargar
                    </Button>
                  </Grid>
                </Grid>
                <TablaResultados
                  columnas={resultadosIndividuales.columnas}
                  filas={resultadosIndividuales.filas}
                  idContador='var'
                  filaRedireccion='/servicios/rv/resultados/anexos-individuales'
                  contextoRedireccion={{ grupoModelo: grupoModelo?.valor, proceso: proceso?.valor }}
                />
              </>
            ) : (
              <>
                <Box sx={css.cardHeader}>
                  <Typography sx={css.h2} className='cardHeader'>
                    Resultados Individuales
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </>
      )}
    </>
  );
}

const css = {
  card: {
    borderRadius: '16px',
    marginBottom: '20px',
    padding: '16px'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'inline-block'
  },
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  actionBtn: {
    boxShadow: 'none',
    backgroundColor: 'tentativo.btnGreen',
    color: 'white',
    borderRadius: 20,
    fontSize: { xs: 14, lg: 17 },
    padding: { xs: '6px 20px', lg: 'inherited' }
  },
  actionBtnTabla: {
    marginRight: 2,
    fontSize: { xs: 12, lg: 15 },
    padding: { xs: '3px 10px', lg: 'inherited' }
  },
  cajaLeyenda: {
    marginTop: 1,
    padding: '0 12px',
    '& > div': {
      margin: '0 8px',
      display: 'inline-block'
    },
    textAlign: 'center'
  },
  leyenda: {
    height: '12px',
    width: '12px',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '2px',
    margin: '0 4px'
  }
};

export default ControlResultados;
