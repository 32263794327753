import { CircularProgress } from '@mui/material';

import { useObtenerDatos } from '../../hooks';

import TSelect from './TSelect';

import * as api from '../../api';

function TSelectMenus({ onChange, label = null, value = null }) {
  const { menus, cargando } = useObtenerDatos({
    nombre: 'menus',
    llamadaAPI: api.interno.obtenerMenus
  });

  return cargando ? (
    <CircularProgress />
  ) : (
    <TSelect
      id='menus'
      label={label ? label : 'Menus'}
      placeholder='Menus'
      opciones={menus.map((m) => ({ id: m.id, nombre: m.nombre }))}
      onChange={onChange}
      {...(value && { value })}
    />
  );
}

export default TSelectMenus;
