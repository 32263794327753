import { useEffect, useState } from 'react';

import * as storage from '../storage';

function useToken() {
  const [token, setToken] = useState(storage.token.obtener());

  const guardarTokenEnLocalStorage = () => {
    setToken(storage.token.obtener());
  };

  useEffect(() => {
    window.addEventListener('storage', guardarTokenEnLocalStorage);

    return function cleanup() {
      window.removeEventListener('storage', guardarTokenEnLocalStorage);
    };
  }, []);

  return token;
}

export default useToken;
