/**
 * Manejar la manipulación del token de usuario en localStorage
 * @module storage/token
 */

import { jwtDecode } from 'jwt-decode';

let sufijo = '';

if (process.env.REACT_APP_ENVIRONMENT === 'testing') {
  sufijo = '_test';
}

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  sufijo = '_dev';
}

/**
 * Key del token en el localStorage
 */
const JWTKey = '_srvcs_thallus_jwt' + sufijo;

/**
 * Obtener token desde localStorage
 * @returns {string}
 */
export const obtener = () => {
  const token = localStorage.getItem(JWTKey);

  return token === 'undefined' ? null : token;
};

/**
 * Guardar token a localStorage
 * @param {string} token
 */
export const guardar = (token) => {
  localStorage.setItem(JWTKey, token);
  window.dispatchEvent(new Event('storage'));
};

/**
 * Quitar token del localStorage
 */
export const remover = () => {
  localStorage.removeItem(JWTKey);

  window.dispatchEvent(new Event('storage'));
};

/**
 * Revisar si token de usuario es válido
 * @returns {bool}
 */
export const esValido = () => {
  const data = decode();

  if (!data) return false;

  let { exp } = data;

  if (exp < Math.round(new Date().getTime() / 1000)) {
    return false;
  }

  return true;
};

export const obtenerDatos = () => decode();

/**
 * Extraer información desde token
 * @returns {(object|null)}
 */
const decode = () => {
  const token = obtener();

  if (!token) return null;

  try {
    return jwtDecode(token);
  } catch (err) {
    return null;
  }
};
