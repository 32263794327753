import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { NotFound, PaginaCargando } from './app/componentes/thallus';

// AT
import Ingresar from './app/componentes/autenticacion/Ingresar';
import RestablecerPassword from './app/componentes/autenticacion/RestablecerPassword';
import NuevaPassword from './app/componentes/autenticacion/NuevaPassword';

// RV
import PanelControl from './app/componentes/servicios/rv/PanelControl';
// -
import ProcesoMensual from './app/componentes/servicios/rv/ProcesoMensual';
import Procesos from './app/componentes/servicios/rv/proceso_mensual/Procesos';
import ArchivosProceso from './app/componentes/servicios/rv/proceso_mensual/ArchivosProceso';
// -
import ControlResultados from './app/componentes/servicios/rv/ControlResultados';
import AnexosIndividuales from './app/componentes/servicios/rv/AnexosIndividuales';
import Maestro from './app/componentes/servicios/rv/Maestro';
import MiRentaVariable from './app/componentes/servicios/rv/MiRentaVariable';
import Configuracion from './app/componentes/servicios/rv/Configuracion';

// AZ
import Menus from './app/componentes/servicios/autorizacion/Menus';
import Recursos from './app/componentes/servicios/autorizacion/Recursos';
import Permisos from './app/componentes/servicios/autorizacion/Permisos';
import PermisosMenus from './app/componentes/servicios/autorizacion/PermisosMenus';
import PermisosUsuarios from './app/componentes/servicios/autorizacion/PermisosUsuarios';

// contenedores
import Autenticacion from './app/componentes/autenticacion';
import Servicios from './app/componentes/servicios';

import * as state from './app/state';

const COMPONENTES = {
  rv: {
    PanelControl,
    ProcesoMensual,
    ControlResultados,
    AnexosIndividuales,
    Maestro,
    MiRentaVariable,
    Configuracion
  },
  az: {
    Menus,
    Recursos,
    Permisos,
    PermisosMenus,
    PermisosUsuarios
  },
  ProcesoMensual: { Procesos, ArchivosProceso }
};

const RUTAS_MANUALES = {
  ProcesoMensual: [
    {
      ruta: 'procesos',
      nombre: 'Procesos',
      componente: 'Procesos'
    },
    {
      ruta: 'archivos',
      nombre: 'ArchivosProceso',
      componente: 'ArchivosProceso'
    }
  ]
};

function Rutas({ menu }) {
  const servicioActivo = useSelector(state.config.servicioActivo);
  const rutaInicial = useSelector(state.config.rutaInicial);

  const rutasHijas = (rutas, servicio) => {
    if (!rutas || rutas.length === 0) return [];

    let hijos = [];

    for (const ruta of rutas) {
      let subrutas = rutasHijas(ruta.hijos, servicio);

      if (!ruta.codigo) {
        hijos.push(
          <Route
            key={ruta.nombre}
            name={ruta.nombre}
            path={ruta.ruta}
            element={<Navigate to={subrutas[0].props.path} />}
          />
        );

        hijos = [...hijos, ...subrutas];
      } else {
        let Componente = COMPONENTES[servicio][ruta.componente];

        if (Componente === undefined) {
          continue;
        }

        if (ruta.componente in RUTAS_MANUALES) {
          let rutasManuales = RUTAS_MANUALES[ruta.componente];

          let manuales = [
            <Route key={ruta.nombre} path={ruta.ruta} element={<Navigate to={rutasManuales[0].ruta} />} />
          ];

          for (const rutaManual of rutasManuales) {
            let Comp = COMPONENTES[ruta.componente][rutaManual.componente];

            manuales.push(
              <Route
                key={rutaManual.nombre}
                name={rutaManual.nombre}
                path={rutaManual.ruta}
                element={<Comp />}
              />
            );
          }

          hijos.push(
            <Route key={ruta.ruta} name={ruta.nombre} path={ruta.ruta} element={<Componente />}>
              {manuales}
            </Route>
          );
        } else {
          hijos.push(
            <Route key={ruta.codigo} name={ruta.nombre} path={ruta.ruta} element={<Componente />} />
          );
        }
      }
    }

    return hijos;
  };

  let rutas = {};

  if (!!menu) {
    for (const ruta of menu) {
      if (ruta.ruta === 'at') continue;

      let subrutas = rutasHijas(ruta.hijos, ruta.ruta);

      if (subrutas.length > 0) {
        rutas[ruta.ruta] = [
          <Route
            key={ruta.ruta}
            path={ruta.ruta}
            name={ruta.nombre}
            element={<Navigate to={subrutas[0].props.path} />}
          />,
          ...subrutas
        ];
      }
    }
  }

  return (
    <Routes>
      {(Object.keys(rutas).length === 0 || !rutaInicial) && <Route path='*' element={<PaginaCargando />} />}

      {rutaInicial && <Route path='/' element={<Navigate to={rutaInicial} />} />}

      <Route path='/autenticacion' name='Autenticación' element={<Autenticacion />}>
        <Route key='ingresar' name='Ingresar' path='ingresar' element={<Ingresar />} />
        <Route
          key='password/restablecer'
          name='Restablecer Contraseña'
          path='password/restablecer'
          element={<RestablecerPassword />}
        />
        <Route
          key='password/crear'
          name='Nueva Contraseña'
          path='password/crear'
          element={<NuevaPassword />}
        />
      </Route>

      {Object.keys(rutas).length > 0 && !!rutaInicial && (
        <Route path='/servicios' name='Servicios' element={<Servicios />}>
          {rutas[servicioActivo]}
          <Route path='*' element={<NotFound />} />
        </Route>
      )}
    </Routes>
  );
}

export default Rutas;
