/**
 * @module app/components/thallus/TCardBoton
 */

import { SummarizeOutlined } from '@mui/icons-material';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

/**
 * Card clickeable
 * @param {object} params
 * @param {(JSX.Element|string)} params.titulo
 * @param {Function} params.onClick
 * @param {object} params.style
 * @param {(JSX.Element|string)} params.children
 * @returns {JSX.Element}
 */
function TCardBoton({ titulo = null, onClick = null, style = null, children }) {
  return (
    <Card sx={{ height: '100%', backgroundColor: 'primary.T90', ...(style && { ...style }) }}>
      <CardActionArea {...(onClick && { onClick })} sx={{ height: '100%' }}>
        <CardContent>
          {titulo && (
            <Typography
              sx={{
                pb: 1,
                color: 'primary.S40',
                textDecoration: 'underline',
                fontSize: '1.2rem',
                ':hover': {
                  color: 'primary.dark'
                }
              }}
            >
              <SummarizeOutlined fontSize='small' /> {titulo}
            </Typography>
          )}
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default TCardBoton;
