/**
 * @module app/components/thallus/TPasswordIndicator
 */

import { LinearProgress, Typography } from '@mui/material';

function PasswordIndicator({ contra }) {
  let seguridad = 0;
  //   let exito = 0;
  const tests = [
    (c) => c.length >= 8,
    // (c) => /[!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]/.test(c),
    (c) => /[A-Z]/.test(c) && /[a-z]/.test(c),
    (c) => /[A-Z]/.test(c),
    (c) => /[a-z]/.test(c),
    (c) => /[0-9]/.test(c)
  ];

  seguridad = (tests.map((test) => +test(contra)).reduce((acc, curr) => acc + curr) * 100) / tests.length;

  if (seguridad > 100) seguridad = 100;

  const sec = _sec(seguridad, contra);

  return (
    <div>
      <LinearProgress
        value={sec.valor}
        variant='determinate'
        sx={{ '&>span': { backgroundColor: sec.color } }}
      />
      <Typography color='#4B635B' fontSize={14} marginTop={0.5}>
        Seguridad de la contraseña:
        <Typography display='inline' fontSize={14} fontWeight='bold' color={sec.colorTexto}>
          {' '}
          {sec.label}
        </Typography>
      </Typography>
    </div>
  );
}

function _sec(porcentaje, contra) {
  if (porcentaje === 0 && contra.trim().length === 0)
    return { color: 'transparent', colorTexto: '#4B635B', label: '-', valor: 0 };
  else if (porcentaje <= 30)
    return { color: '#D96266', colorTexto: '#D96266', label: 'Baja', valor: porcentaje };
  else if (porcentaje <= 74)
    return { color: '#D19233', colorTexto: '#D19233', label: 'Media', valor: porcentaje };
  else if (porcentaje <= 100)
    return { color: '#7bdea7', colorTexto: '#23b8a0', label: 'Fuerte', valor: porcentaje };
}

export default PasswordIndicator;
