import axios from 'axios';
import { md5 } from 'hash-wasm';

export const deviceHash = async () => {
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  let txt = 'PLATAFORMA_THALLUS';
  ctx.textBaseline = 'top';
  ctx.font = "14px 'Arial'";
  ctx.textBaseline = 'alphabetic';
  ctx.fillStyle = '#77DDA7';
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = '#74D896';
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = 'rgb(122, 110, 102)';
  ctx.fillText(txt, 4, 17);

  return md5(canvas.toDataURL());
};

export const clientIP = async () => {
  return await axios.get('https://api.ipify.org?format=json');
};
