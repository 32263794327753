import { Grid, Paper, Typography } from '@mui/material';
import { Fragment } from 'react';

function AnexoRV({ anexoTrabajador }) {
  return (
    <>
      <Paper sx={css.card}>
        <Typography sx={css.h2}>Información Personal</Typography>
        <Grid container spacing={1.1}>
          {anexoTrabajador.personal.map((elemento, i) => (
            <Grid item xs={12} md={4} xl={3} sx={css.entrada} key={i}>
              <Typography sx={css.entradaTitulo}>{elemento.nombre}</Typography>
              <Typography sx={css.entradaValor}>{formatear(elemento)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Paper sx={{ ...css.card, ...css.entradas }}>
        {anexoTrabajador.detalle.map((detalle, i) => {
          if (detalle.indicadores.length === 0) {
            return null;
          }

          return (
            <Fragment key={i}>
              <Typography sx={css.h2}>{detalle.titulo}</Typography>
              <Grid container spacing={1.1}>
                {detalle.indicadores.map((indicador, j) => {
                  return !indicador.total ? (
                    <Grid key={j} item xs={12} md={4} xl={3} sx={css.entrada}>
                      <Typography sx={css.entradaTitulo}>{indicador.nombre}</Typography>
                      <Typography sx={css.entradaValor}>{formatear(indicador)}</Typography>
                    </Grid>
                  ) : (
                    <Grid key={j} container item xs={12} sx={css.total}>
                      <Grid item xs={12} md={10}>
                        <Typography align='right' sx={css.entradaValor}>
                          {indicador.nombre}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography align='right' sx={css.entradaValor}>
                          {formatear(indicador)}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              {!!detalle.texto_inferior
                ? detalle.texto_inferior.map((texto, j) => {
                    return (
                      <Typography key={j} sx={css.textoInferior}>
                        {texto}
                      </Typography>
                    );
                  })
                : undefined}
              <hr style={css.hr} />
            </Fragment>
          );
        })}
      </Paper>
    </>
  );
}

const css = {
  card: {
    borderRadius: '16px',
    marginBottom: '20px',
    padding: '16px'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  entrada: {},
  total: {
    padding: '12px 14px',
    borderRadius: '12px',
    mt: 1,
    backgroundColor: '#f2f2f2',
    pb: 1
  },
  entradaTitulo: {
    fontSize: 14,
    display: 'block',
    color: '#989898'
  },
  entradaValor: {
    fontSize: 14,
    display: 'block',
    fontWeight: 500,
    color: 'tentativo.textGreen'
  },
  textoInferior: {
    fontSize: 14,
    display: 'block',
    fontWeight: 500,
    fontStyle: 'italic',
    marginTop: 3.75
  },
  hr: {
    borderColor: 'rgba(0,0,0,.16)',
    margin: '15px 0'
  },
  entradas: {
    '& hr:last-of-type': { display: 'none' }
  }
};

function formatear(indicador) {
  switch (indicador.tipo) {
    case 'int':
      return <>{`${Math.round(indicador.valor).toLocaleString('es-CL')}`}</>;
    case 'segundos':
      const horas = Math.floor(indicador.valor / 3600);
      const minutos = Math.floor((indicador.valor % 3600) / 60);
      return <>{`${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`}</>;
    case 'moneda':
      return <>{`${Math.round(indicador.valor).toLocaleString('es-CL')}`}</>;
    default:
      const _tipo = typeof indicador.valor;
      switch (_tipo) {
        case 'string':
          return <>{indicador.valor}</>;
        case 'number':
          return <>{indicador.valor.toLocaleString('es-CL')}</>;
        case 'boolean':
          return <>{indicador.valor ? 'Sí' : 'No'}</>;
        default:
          return <>{indicador.valor}</>;
      }
  }
}

export default AnexoRV;
