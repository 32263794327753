/**
 * @module app/components/thallus/TBotonSubmit
 */

//import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
//import { useState } from 'react';

const css = {
  boton: {
    padding: '15px 25px',
    fontSize: '0.8rem',
    transition: 'all .4s ease',
    webkitTransition: 'all .4s ease',
    fontFamily:'Poppins',
    fontWeight:'600',
    fontSize:'16px',
    lineHeight:'24px',
    color:'#FFF',
    textTransform:'inherit',
    borderRadius:'10px',
    ':hover': {
      // mt: 2,
      // pr: 2,
      // pl: 4,
      transition: 'all .4s ease',
      webkitTransition: 'all .4s ease'
    }
  }
  /*
  # Durante la última reunión se acordó remover la flecha, pero lo dejo comentado por si vuelve más adelante.
  o en caso de que el componente se vuelva a utilizar y la flecha sea opcional

  mostrarFlecha: {
    display: 'block',
    transition: 'all .4s ease',
    webkitTransition: 'all .4s ease'
  },
  ocultarFlecha: {
    display: 'none',
    transition: 'all .4s ease',
    webkitTransition: 'all .4s ease'
  }*/
};

/**
 * Botón enviar formulario
 * @param {object} params
 * @param {JSX.Element} params.children
 * @returns {JSX.Element}
 */
function TBotonSubmit({ children }) {
  //const [hover, setHover] = useState(false);

  return (
    <Button
      type='submit'
      color='primary'
      variant='contained'
      sx={css.boton} /*
      startIcon={<ArrowForward sx={hover ? css.mostrarFlecha : css.ocultarFlecha} />}
      endIcon={<ArrowForward sx={hover ? css.ocultarFlecha : css.mostrarFlecha} />}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}*/
      disableElevation
    >
      {children}
    </Button>
  );
}

export default TBotonSubmit;
