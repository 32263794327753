/**
 * @module app/components/autenticacion/Ingresar
 */

import { Grid, Typography } from '@mui/material';

import TFormularioIngresar from './TFormularioIngresar';
import TAuthCard from './TAuthCard';

/**
 * Contenedor formulario de ingreso
 * @returns {JSX.Element}
 */
function Ingresar() {
  //const gaEventTracker = useAnalyticsEventTracker('Login');

  return (
    <Grid item container xs={12} md={12}>
      <Grid item xs={12} container direction='column' alignItems='center' justifyContent='center'>
        <TAuthCard>
          <Typography textAlign='left' color='primary.S80' sx={css.h5}>
            Inicia Sesión
          </Typography>
          <Typography textAlign='left' color='primary.S80' sx={css.h3}>
            Ingresa tus datos
          </Typography>
          <TFormularioIngresar />
        </TAuthCard>
      </Grid>
    </Grid>
  );
}

// TODO : Mover a theme.js u similar
const css = {
  h3: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '1.875rem'
  },
  h5: {
    fontFamily: 'Poppins',
    fontWeight: '300',
    fontSize: '1.25rem'
  }
};

export default Ingresar;
