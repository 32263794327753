/**
 * Llamadas a api de autenticación
 * @module app/api/autenticacion
 */

import { autenticacion } from './_instancias';
import * as storage from '../storage';

/**
 * Login
 * @async
 * @param {object} params
 * @param {object} params.credenciales
 * @param {string} params.credenciales.username
 * @param {string} params.credenciales.password
 * @returns {object}
 */
const login = async ({ credenciales }) =>
  (await autenticacion.post('/login_check', credenciales, { noToken: true })).data;

const obtenerUsuarios = async () => (await autenticacion.get('/usuarios')).data;

const obtenerUsuariosConPermisos = async () => (await autenticacion.get('/usuarios/permisos')).data;

const obtenerUsuario = async () => {
  const { sub } = storage.token.obtenerDatos();

  return (await autenticacion.get(`/usuario/${sub}`)).data;
};

const infoUsuario = async () => {
  const { sub } = storage.token.obtenerDatos();

  return (await autenticacion.get(`/usuario/${sub}/info`)).data;
};

const solictarRestablecerPassword = async ({ datos }) =>
  await autenticacion.post('/usuario/password/restablecer', datos, { noToken: true });

const verificarTokenRestablecerPassword = async ({ datos }) =>
  await autenticacion.get('/usuario/password/restablecer', { params: datos, noToken: true });

const restablecerPassword = async ({ datos }) =>
  await autenticacion.put('/usuario/password/restablecer', datos, { noToken: true });

export {
  login,
  obtenerUsuarios,
  obtenerUsuariosConPermisos,
  obtenerUsuario,
  infoUsuario,
  solictarRestablecerPassword,
  verificarTokenRestablecerPassword,
  restablecerPassword
};
