import { CircularProgress } from '@mui/material';

import { useObtenerDatos } from '../../hooks';

import TSelect from './TSelect';

import * as api from '../../api';

function TSelectPermisos({ onChange, label = null, value = null }) {
  const { listadoPermisos, cargando } = useObtenerDatos({
    nombre: 'listadoPermisos',
    llamadaAPI: api.autorizacion.obtenerPermisos
  });

  return cargando ? (
    <CircularProgress />
  ) : (
    <TSelect
      id='listadoPermisos'
      label={label ? label : 'Permisos'}
      placeholder='Permisos'
      opciones={[
        ...listadoPermisos.map((lp) => ({
          id: lp.id,
          nombre: lp.p.join('.') + (lp.subrcrso ? `[${lp.subrcrso}]` : '')
        }))
      ]}
      onChange={onChange}
      multiple
      {...(value && { value })}
    />
  );
}

export default TSelectPermisos;
