import { useProcesos } from '../../../../hooks';

import { Box, List, ListItem, Typography } from '@mui/material';

import SelectorGrupoModelo from '../comun/SelectorGrupoModelo';
import MesItem from './MesItem';

function Procesos() {
  const { grupoModelo, gruposModelos } = useProcesos({ procesoInicial: false });

  const onCambioGrupo = (grupo) => {
    grupoModelo.setGrupoModelo(grupo);
  };

  return (
    <>
      <Typography sx={{ ...css.h1 }} component={'span'}>
        Procesos mensuales
        {gruposModelos?.length > 1 && (
          <>
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
          </>
        )}
      </Typography>
      <Typography sx={{ ...css.sectionDesc }}>
        Acá podrás subir los archivos correspondientes de cada mes{' '}
      </Typography>

      <Box sx={css.scroller}>
        <Box sx={{ ...css.header, minWidth: 850 }}>
          <Typography sx={{ ...css.headerItem }}>Mes</Typography>
          <Typography sx={{ ...css.headerItem }}>Archivos</Typography>
          <Typography sx={{ ...css.headerItem }}>Estado</Typography>
        </Box>
        {!grupoModelo?.procesos ? (
          <MesItem />
        ) : (
          grupoModelo?.procesos
            ?.filter((p) => p.estado !== 'CERRADO')
            .map((p, i) => (
              <ListItem disablePadding key={i}>
                <MesItem proceso={p} />
              </ListItem>
            ))
        )}
      </Box>
      <Typography sx={{ ...css.h1, mt: 4 }}>Meses cerrados</Typography>
      <Box sx={css.scroller}>
        <Box sx={{ ...css.header, minWidth: 850 }}>
          <Typography sx={{ ...css.headerItem }}>Mes</Typography>
          <Typography sx={{ ...css.headerItem }}>Archivos</Typography>
          <Typography sx={{ ...css.headerItem }}>Estado</Typography>
        </Box>
        <List>
          {!grupoModelo?.procesos ? (
            <>
              <ListItem disablePadding>
                <MesItem />
              </ListItem>
              <ListItem disablePadding>
                <MesItem />
              </ListItem>
            </>
          ) : (
            grupoModelo?.procesos
              ?.filter((p) => p.estado === 'CERRADO')
              .map((p, i) => (
                <ListItem disablePadding key={i}>
                  <MesItem proceso={p} />
                </ListItem>
              ))
          )}
        </List>
      </Box>
    </>
  );
}

// TODO: Limpiar este css
const css = {
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 'bold',
    margin: 0
  },
  subSection: {
    position: 'relative',
    display: 'inline-block',
    fontSize: { xs: 20, lg: 28 },
    fontWeight: 500,
    margin: '0 0px 12px 38px',
    borderBottom: '2px solid #539170'
  },
  sectionDesc: {
    fontSize: { xs: 15, lg: 17 },
    fontWeight: 'light'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    px: 5,
    mt: 1
  },
  headerItem: {
    color: '#5B5B5B',
    fontSize: 14,
    fontWeight: 'medium',
    // width: '30%',
    width: '33%'
  },
  scroller: {
    maxWidth: { xs: 'calc(100% + 32px)', sm: 'calc(100% + 74px)' },
    overflowX: 'auto',
    margin: { xs: '0 -16px', sm: '0 0px' }
  }
};

export default Procesos;
