/**
 * Llamadas a api de rv
 * @module app/api/rv
 */

import { rv } from './_instancias';
// import { obtenerEmpresa } from './interno';

const obtenerModelos = async () => {
  // let empresa = jwtDecode(token).empresa;

  // let response = await obtenerEmpresa(empresa, token);

  // console.log(response);

  // if (response) {
  //   rv.defaults.baseURL =
  //     response.servidor !== '' ? response.servidor + '/api/rv/v1' : rv.defaults.baseURL;
  // }

  return (await rv.get('/modelos')).data;
};

const obtenerConfiguracion = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/configuracion`)).data;

const obtenerSchemaConfiguracion = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/configuracion/schema`)).data;

const obtenerProcesos = async () => (await rv.get('/procesos')).data;

const obtenerResIndicadores = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/resultados/indicadores`)).data;

const obtenerResEvolucion = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/resultados/generales/evolucion`)).data;

const obtenerResGenerales = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/resultados/generales`)).data;

const obtenerTrabajadores = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/trabajadores`)).data;

const obtenerAnexoTrabajador = async ({ proceso, rut }) =>
  (await rv.get(`/procesos/${proceso}/trabajador/${rut}`)).data;

const descargarAnexoTrabajador = async ({ proceso, rut }) =>
  await rv.get(`/procesos/${proceso}/trabajador/${rut}`, {
    headers: {
      accept: 'application/pdf'
    }
  });

const obtenerResumenTrabajador = async ({ proceso, rut }) =>
  (await rv.get(`/procesos/${proceso}/trabajador/${rut}/resumen`)).data;

const obtenerHistoricoTrabajador = async ({ proceso, rut }) =>
  (await rv.get(`/procesos/${proceso}/trabajador/${rut}/historico`)).data;

const obtenerMaestro = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/maestro`)).data;

const descargarMaestro = async ({ proceso }) =>
  await rv.get(`/procesos/${proceso}/maestro`, {
    headers: {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  });

const obtenerResIndividuales = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/resultados/individuales`)).data;

const descargarResIndividuales = async ({ proceso }) =>
  await rv.get(`/procesos/${proceso}/resultados/individuales`, {
    headers: {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  });

const obtenerArchivosProceso = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/archivos`)).data;

const obtenerGCSArchivo = async ({ proceso, archivo }) =>
  (await rv.get(`/procesos/${proceso}/archivos/${archivo}/gcs`)).data;

const descargarGCSProceso = async ({ proceso }) =>
  await rv.get(`/procesos/${proceso}/gcs`, { headers: { accept: 'application/zip' } });

const subirArchivoProceso = async ({ proceso, archivo, data, onUploadProgress }) =>
  (await rv.post(`/procesos/${proceso}/archivos/${archivo}`, data)).data;

const calcularProceso = async ({ proceso }) =>
  (await rv.post(`/procesos/${proceso}/procesar`, undefined)).data;

const cerrarProceso = async ({ proceso }) => (await rv.put(`/procesos/${proceso}/cerrar`, undefined)).data;

const obtenerIndicadores = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/indicadores`)).data;

const obtenerRanking = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/ranking`)).data;

const obtenerEvolucion = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/evolucion`)).data;

const obtenerComposicionPorc = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/composicion/porcentaje`)).data;

const obtenerComposicionAbs = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/composicion/absoluto`)).data;

const guardarConfiguracionProceso = async ({ proceso, config }) =>
  await rv.post(`/procesos/${proceso}/configuracion`, config);

const obtenerSchemaParametros = async ({ proceso }) =>
  (await rv.get(`/procesos/${proceso}/parametros/schema`)).data;

const obtenerParametros = async ({ proceso }) => (await rv.get(`/procesos/${proceso}/parametros`)).data;

const guardarParametrosProceso = async ({ proceso, parametros }) =>
  await rv.post(`/procesos/${proceso}/parametros`, parametros);

export {
  obtenerModelos,
  obtenerProcesos,
  obtenerArchivosProceso,
  obtenerGCSArchivo,
  descargarGCSProceso,
  subirArchivoProceso,
  obtenerResIndividuales,
  obtenerResGenerales,
  obtenerTrabajadores,
  obtenerAnexoTrabajador,
  obtenerResumenTrabajador,
  calcularProceso,
  obtenerMaestro,
  cerrarProceso,
  obtenerResIndicadores,
  obtenerIndicadores,
  obtenerRanking,
  obtenerEvolucion,
  obtenerComposicionPorc,
  obtenerComposicionAbs,
  descargarMaestro,
  obtenerConfiguracion,
  guardarConfiguracionProceso,
  obtenerResEvolucion,
  obtenerSchemaConfiguracion,
  obtenerHistoricoTrabajador,
  obtenerSchemaParametros,
  obtenerParametros,
  guardarParametrosProceso,
  descargarAnexoTrabajador,
  descargarResIndividuales
};
