/**
 * @module hooks/useGuardarDatos
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

/**
 * Hook alrededor de `useMutation` de `react-query` para simplificar su uso. Usar preferentemente
 * para peticiones en que se vaya a modificar/agregar datos y se necesite refrescar la caché interna.
 *
 * @param {object} params
 * @param {Function} params.llamadaAPI - función que se ejecutará para hacer la petición al backend,
 * puede ser alguna de las funciones en el directorio `api` o una función anónima envolviendo una
 * llamada con `axios`
 * @param {object} params.parametros - parametros de llamadaAPI
 * @param {Function} params.onSuccess - función que se ejecuta en caso de una petición exitosa
 * @param {Function} params.onError - función que se ejecuta en caso de una petición fallida
 * @param {(Array)} params.invalidate - la o las llaves para refrescar la caché
 * @returns {object}
 */
function useGuardarDatos({
  llamadaAPI,
  parametros = null,
  onSuccess = null,
  onError = null,
  cacheActualizar = [],
  tipo = 'crear',
  nombre = null
}) {
  const navigate = useNavigate();

  if (!llamadaAPI) {
    throw new Error('Falta llamadaAPI');
  }

  if (cacheActualizar) {
    if (!Array.isArray(cacheActualizar)) {
      throw new Error('cacheActualizar debe ser un array');
    }

    for (const key of cacheActualizar) {
      if (typeof key !== 'string') {
        throw new Error('cacheActualizar debe ser un array de strings');
      }
    }
  }

  if (parametros && typeof parametros !== 'object') {
    throw new Error('parametros debe ser un objeto');
  }

  if (parametros && 'token' in parametros) {
    delete parametros.token;
  }

  if (!parametros) {
    parametros = {};
  }

  const qc = useQueryClient();

  const { mutate, isPending, error, isSuccess } = useMutation({
    mutationFn: (args) => llamadaAPI({ ...args }),
    // onMutate: (variables) => {
    //   console.log({ ...variables, ...parametros });
    //   return { ...variables, ...parametros };
    // },
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);

      return Promise.all(
        cacheActualizar.map((item) =>
          item.includes('*')
            ? qc.invalidateQueries({
                predicate: (query) => query.queryKey[0].includes(item.replace('*', ''))
              })
            : qc.invalidateQueries({ queryKey: [item] })
        )
      );
    },
    onError: (error) => {
      if (error.code === 'ERR_CANCELED') {
        navigate('/autenticacion/ingresar', { replace: true });
      }
      if (onError) onError(error);
    }
  });

  const accion = `${tipo}${nombre.charAt(0).toUpperCase() + nombre.slice(1)}`;

  return {
    [accion]: (args) => {
      mutate(args);
    },
    cargando: isPending,
    error: error && { status: error.response.status, data: error.response.data.msg },
    guardado: isSuccess
  };
}

export default useGuardarDatos;
