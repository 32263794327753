/**
 * @module app/components/thallus/NotFound
 */

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';

import * as state from '../../state';

/**
 * Paǵina 404
 * @returns {JSX.Element}
 */
function NotFound() {
  const navigate = useNavigate();
  const rutaInicial = useSelector(state.config.rutaInicial);

  const onClick = () => {
    navigate(rutaInicial, { replace: true });
  };

  return (
    <>
      <h1>Ups parece que la página no existe</h1>
      Volver al{' '}
      <Link onClick={onClick} style={{ color: '#539170', fontWeight: 600, cursor: 'pointer' }}>
        Inicio
      </Link>
    </>
  );
}

export default NotFound;
