import { Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const drawerWidth = 250;
export const topBarHeight = 45;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: `${topBarHeight}px !important`,
  maxHeight: `${topBarHeight}px !important`
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: '#FFF',
  color: theme.palette.primary.S80,
  overflowX: 'hidden',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    color: theme.palette.primary.S80,
    overflowX: 'hidden'
  }
}));
