import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useObtenerDatos, useProcesos } from '../../../hooks';

import { ArrowBack } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, CircularProgress, IconButton, Paper, Typography } from '@mui/material';

import { TInputTexto } from '../../thallus';

import AnexoRV from './anexos/AnexoRV';
import SelectorProcesos from './comun/SelectorProceso';
import SelectorGrupoModelo from './comun/SelectorGrupoModelo';
import InfoBlock from './comun/InfoBlock';

import * as api from '../../../api';

const estados = ['CERRADO'];

function AnexosIndividuales() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { grupoModelo, gruposModelos, proceso } = useProcesos({ estados });

  let [rut, setRut] = useState(state?.fila?.id);

  let [resultados, setResultados] = useState([]);
  let [busqueda, setBusqueda] = useState('');

  const [descargando, setDescargando] = useState(false);

  let errorProceso = { errId: 1, razon: 'Cargando Anexo Individual ...', tipo: 'cargando' };

  const { [`trabajadores_proceso_${proceso?.valor?.codigo}`]: trabajadoresProceso } = useObtenerDatos({
    nombre: `trabajadores_proceso_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerTrabajadores,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor && ['CERRADO'].includes(proceso?.valor?.estado)
  });

  // if (!!trabajadoresProceso) {
  //   setResultados(trabajadoresProceso);
  // }

  const { [`anexo_${proceso?.valor?.codigo}_trabajador_${rut}`]: anexoTrabajador } = useObtenerDatos({
    nombre: `anexo_${proceso?.valor?.codigo}_trabajador_${rut}`,
    llamadaAPI: api.rv.obtenerAnexoTrabajador,
    parametros: { proceso: proceso?.valor?.codigo, rut: rut },
    condicion: !!proceso?.valor && !!rut && (state?.contexto || ['CERRADO'].includes(proceso?.valor?.estado))
  });

  const onCambioProceso = (codigo) => {
    proceso.setProceso(grupoModelo?.procesos.find((p) => p.codigo === codigo));

    onBusqueda('');
  };

  const onCambioGrupo = (grupo) => {
    proceso.setProceso(null);
    grupoModelo.setGrupoModelo(grupo);

    setBusqueda('');
  };

  function regresarAResultados() {
    if (state?.contexto) {
      navigate('../rv/resultados/control-resultados', {
        state: { contexto: { grupoModelo: grupoModelo?.valor, proceso: proceso?.valor?.codigo } }
      });
    } else {
      setRut(null);
    }
  }

  // TODO: Optimizar esta búsqueda. Contar segundos entre búsqueda y búsqueda
  function onBusqueda(value) {
    let _busqueda = value.trimStart().replace('.').toLowerCase();

    if (_busqueda === '') setResultados(trabajadoresProceso);

    setBusqueda(_busqueda);

    let _incidencias = 0;
    if (trabajadoresProceso) {
      setResultados(
        trabajadoresProceso.filter((persona) => {
          if (_incidencias > 5) return false;
          else if (
            persona.id.toString().indexOf(_busqueda) !== -1 ||
            persona.nombre.toLowerCase().indexOf(_busqueda) !== -1
          ) {
            _incidencias++;
            return true;
          }
          return false;
        })
      );
    }
    // else {
    //   setResultados([{ nombre: `Cargando resultados del proceso ${proceso?.valor?.nombre} ...`, id: '' }]);
    // }
  }

  const onDescargarAnexo = () => {
    setDescargando(true);
    api.rv
      .descargarAnexoTrabajador({ proceso: proceso.valor.codigo, rut })
      .then((res) => res.data)
      .then((data) => {
        const enlace = `data:${data.type};base64,${data.file}`;

        const _a = document.createElement('a');
        _a.href = enlace;
        _a.setAttribute('download', `${data.filename}.${data.ext}`);
        _a.click();
        setDescargando(false);
      });
  };

  if (!rut) {
    if (!!grupoModelo?.valor) {
      if (grupoModelo?.procesos?.length === 0) {
        errorProceso = {
          errId: -1,
          razon: 'No hay procesos cerrados disponibles',
          tipo: 'info'
        };
      }

      if (!!proceso?.valor) {
        if (proceso.valor.estado !== 'CERRADO') {
          errorProceso = { errId: 10, razon: `Proceso no se ha cerrado aún.`, tipo: 'info' };
        } else {
          errorProceso = { errId: 0 };
        }
      }
    }
  } else {
    if (!anexoTrabajador) {
      errorProceso = { errId: 1, razon: 'Cargando Anexo Individual ...', tipo: 'cargando' };
    } else {
      errorProceso = { errId: 0 };
    }
  }

  return (
    <>
      <Box
        sx={{
          py: '20px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        <Typography sx={{ ...css.h1 }} component={'span'}>
          {!!rut && (
            <IconButton onClick={() => regresarAResultados()}>
              <ArrowBack />
            </IconButton>
          )}
          Anexo Individual Renta Variable
          {!rut && gruposModelos?.length > 1 && (
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
          )}
          {grupoModelo?.procesos?.length > 0 && !state?.contexto && (
            <SelectorProcesos
              onCambioProceso={onCambioProceso}
              proceso={proceso?.valor?.codigo}
              procesos={grupoModelo.procesos}
            />
          )}
        </Typography>

        {!!rut && !state?.contexto && (
          <Box sx={{ textAlign: 'right', flexGrow: 1 }}>
            <Button
              variant='contained'
              sx={{ ...css.subActionBtn }}
              size='large'
              startIcon={<SearchOutlinedIcon />}
              onClick={() => {
                setRut(undefined);
              }}
            >
              Revisar Otro Detalle
            </Button>
            {/* <Button variant='contained' sx={{ ...css.actionBtn }} size='large' onClick={onDescargarAnexo}>
              {descargando && <CircularProgress size={18} sx={{ mr: 1, color: '#539170' }} />}
              Descargar Anexo
            </Button> */}
          </Box>
        )}
      </Box>
      {errorProceso.errId ? (
        <InfoBlock tipo={errorProceso.tipo}>{errorProceso.razon}</InfoBlock>
      ) : !!rut && !!anexoTrabajador ? (
        <AnexoRV rut={rut} anexoTrabajador={anexoTrabajador} />
      ) : (
        <>
          <Typography sx={{ ...css.sectionDesc }}>
            Acá podrás revisar los resultados individuales. Por favor, busca la persona que quieras consultar
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TInputTexto
              variant='outlined'
              label='Buscar Persona'
              placeholder='RUT o Nombre'
              onChange={(e) => onBusqueda(e.target.value)}
              value={busqueda}
            />
            {!!busqueda ? (
              <Paper sx={{ ...css.card, mt: 1 }}>
                <Typography sx={{ fontSize: '14px', marginBottom: 0.5, color: '#989898' }}>
                  Primeras 6 incidencias ...
                </Typography>
                {resultados.map((persona, i) => {
                  return (
                    <Box
                      key={i}
                      sx={css.resultado}
                      onClick={() => {
                        setRut(persona.id);
                      }}
                    >
                      <Typography>
                        {persona.nombre}
                        <span style={css.flr}>{persona.id}</span>
                      </Typography>
                    </Box>
                  );
                })}
              </Paper>
            ) : undefined}
          </Box>
        </>
      )}
    </>
  );
}

const css = {
  card: {
    borderRadius: '16px',
    marginBottom: '20px',
    padding: '16px'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  h2: {
    fontSize: { xs: 17, lg: 20 },
    fontWeight: 600,
    margin: 0,
    marginBottom: 1
  },
  actionBtn: {
    boxShadow: 'none',
    backgroundColor: 'tentativo.btnGreen',
    color: 'white',
    borderRadius: 20,
    fontSize: { xs: 14, lg: 17 },
    padding: { xs: '6px 20px', lg: 'inherited' }
  },
  subActionBtn: {
    boxShadow: 'none',
    backgroundColor: '#cee6d8',
    color: 'tentativo.menuText',
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 600,
    padding: '6px 20px'
  },
  entrada: {},
  entradaTitulo: {
    fontSize: 14,
    display: 'block',
    color: '#989898'
  },
  entradaValor: {
    fontSize: 14,
    display: 'block',
    fontWeight: 500,
    color: 'tentativo.textGreen'
  },
  textoInferior: {
    fontSize: 14,
    display: 'block',
    fontWeight: 500,
    fontStyle: 'italic',
    marginTop: 3.75
  },
  hr: {
    borderColor: 'rgba(0,0,0,.16)',
    margin: '15px 0'
  },
  entradas: {
    '& hr:last-of-type': { display: 'none' }
  },
  flr: { marginLeft: '12px', color: '#989898' },
  resultado: {
    display: 'block',
    padding: '4px 12px',
    cursor: 'pointer',
    borderRadius: 1,
    '&:hover': {
      backgroundColor: '#e5f6ec'
    }
  }
};

export default AnexosIndividuales;
