/**
 * @module hooks/useSubirArchivo
 */
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

function useSubirArchivo({ llamadaAPI, onSuccess = null, onError = null, cacheActualizar = null }) {
  const navigate = useNavigate();

  if (!llamadaAPI) {
    throw new Error('Falta llamadaAPI');
  }

  if (cacheActualizar) {
    if (!Array.isArray(cacheActualizar)) {
      throw new Error('cacheActualizar debe ser un array');
    }

    for (const key of cacheActualizar) {
      if (typeof key !== 'string') {
        throw new Error('cacheActualizar debe ser un array de strings');
      }
    }
  }

  const qc = useQueryClient();

  const [progress, setProgress] = useState(0);

  const { mutate, error, isError } = useMutation({
    mutationFn: (args) =>
      llamadaAPI({
        ...args,
        onUploadProgress: (ev) => setProgress(Math.round((ev.loaded * 100) / ev.total))
      }),
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);

      return Promise.all(
        cacheActualizar.map((item) =>
          item.includes('*')
            ? qc.invalidateQueries({
                predicate: (query) => query.queryKey[0].includes(item.replace('*', ''))
              })
            : qc.invalidateQueries({ queryKey: [item] })
        )
      );
    },
    onError: (error) => {
      if (error.code === 'ERR_CANCELED') {
        navigate('/autenticacion/ingresar', { replace: true });
      }

      if (onError) onError(error);
    },
    retry: (failureCount, error) => failureCount <= 2 && error.response.status === 401
  });

  return {
    subirArchivo: (args) => {
      mutate(args);
    },
    progress,
    errores: isError && !!error && { status: error.response.status, data: error.response.data.msg }
  };
}

export default useSubirArchivo;
