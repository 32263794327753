import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobile: window.screen.width <= 810,
  servicioActivo: 'rv',
  rutaInicial: null
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setServicioActivo: (state, action) => {
      state.servicioActivo = action.payload;
    },
    setRutaInicial: (state, action) => {
      state.rutaInicial = action.payload;
    },
    resetear: (state) => {
      state.isMobile = null;
    }
  }
});

export const { resetear, setIsMobile, setServicioActivo, setRutaInicial } = configSlice.actions;

export const isMobile = (state) => state.config.isMobile;
export const servicioActivo = (state) => state.config.servicioActivo;
export const rutaInicial = (state) => state.config.rutaInicial;

export default configSlice.reducer;
