import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { useGuardarDatos, useObtenerDatos } from '../../../hooks';

import * as api from '../../../api';
import * as state from '../../../state';

function Permisos() {
  const isMobile = useSelector(state.config.isMobile);

  const { servicios } = useObtenerDatos({
    nombre: 'servicios',
    llamadaAPI: api.interno.obtenerServicios
  });

  const { listadoPermisos } = useObtenerDatos({
    nombre: 'listadoPermisos',
    llamadaAPI: api.autorizacion.obtenerPermisos
  });

  const { acciones } = useObtenerDatos({
    nombre: 'acciones',
    llamadaAPI: api.autorizacion.obtenerAcciones
  });

  const { recursos } = useObtenerDatos({
    nombre: 'recursos',
    llamadaAPI: api.autorizacion.obtenerRecursos
  });

  const [permisos, setPermisos] = useState([]);

  const { editarListadoPermisos } = useGuardarDatos({
    nombre: 'ListadoPermisos',
    llamadaAPI: api.autorizacion.guardarPermisos,
    cacheActualizar: ['listadoPermisos'],
    tipo: 'editar',
    onSuccess: () => {
      setPermisos([]);
    }
  });

  const onClickPermiso = (e, permiso) => {
    if (!listadoPermisos?.map(({ p }) => p.join('.')).includes(permiso)) {
      if (e.target.checked) {
        setPermisos([...new Set([...permisos, permiso])]);
      } else {
        setPermisos([...permisos.filter((p) => p !== permiso)]);
      }
    } else {
      // TODO: implementar borrar permisos
    }
  };

  const guardarCambiosEnPermisos = (e) => {
    if (permisos.length > 0) {
      editarListadoPermisos({ permisos: permisos.map((p) => p.split('.')) });
    }
  };

  return (
    <Grid
      container
      direction='row'
      alignItems='stretch'
      sx={{
        width: '100%',
        display: 'grid',
        gap: 0,
        overflowY: 'scroll',
        ...(!isMobile ? { px: 3, py: 1 } : { px: 1, py: 0 })
      }}
    >
      {servicios && acciones && recursos && permisos ? (
        <TableContainer component={Paper}>
          <Table stickyHeader size='small' padding='none'>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...css.th }}>
                  <Button
                    color='primary'
                    variant='contained'
                    {...(permisos.length === 0 && { disabled: true })}
                    disableElevation
                    onClick={guardarCambiosEnPermisos}
                  >
                    Guardar {Math.abs(permisos.length)} Permisos Nuevos
                  </Button>
                </TableCell>
                {servicios.map(({ nombre, slug }) => (
                  <TableCell
                    key={slug}
                    colSpan={acciones.length}
                    style={{
                      ...css.th,
                      borderLeft: '1px solid #000'
                    }}
                  >
                    {nombre} ({slug})
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                {servicios.map((s, i) => (
                  <Fragment key={i}>
                    {acciones.map(({ nombre }) => (
                      <TableCell
                        key={s.lug + nombre}
                        style={{
                          ...css.th,
                          fontSize: '0.7rem',
                          ...(nombre !== 'CREAR'
                            ? { borderLeft: '1px solid rgba(224, 224, 224, 1)' }
                            : { borderLeft: '1px solid #000' }),
                          width: '55px'
                        }}
                      >
                        {nombre}
                      </TableCell>
                    ))}
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {recursos.map((r) => (
                <TableRow key={r.id}>
                  <TableCell sx={{ px: 1 }}>{r.nombre}</TableCell>
                  {servicios.map((s, i) => (
                    <Fragment key={i}>
                      {acciones.map((a) => (
                        <TableCell
                          key={a.id}
                          sx={{
                            ...(a.nombre !== 'CREAR'
                              ? { borderLeft: '1px solid rgba(224, 224, 224, 1)' }
                              : { borderLeft: '1px solid #000' }),
                            textAlign: 'center'
                          }}
                        >
                          <Checkbox
                            checked={
                              listadoPermisos
                                ?.map(({ p }) => p.join('.'))
                                .includes(`${s.slug}.${r.nombre}.${a.nombre}`) ||
                              permisos.includes(`${s.slug}.${r.nombre}.${a.nombre}`)
                            }
                            onChange={(e) => onClickPermiso(e, `${s.slug}.${r.nombre}.${a.nombre}`)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{ p: 1, '&.Mui-checked': { color: 'primary.S50' } }}
                            size='small'
                            disableRipple
                          />
                        </TableCell>
                      ))}
                    </Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
}

const css = {
  th: {
    fontSize: '0.9rem',
    fontWeight: 'normal',
    textAlign: 'center'
  }
};

export default Permisos;
