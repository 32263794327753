/**
 * @module app/components/thallus/TFormularioIngresar
 */

import { useEffect, useState } from 'react';
import { useObtenerDatos } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { Alert, Box, CircularProgress, Typography, Stack } from '@mui/material';
import TBotonSubmit from './TBotonSubmit';
import TInputPassword from './TInputPassword';
import TInputTexto from '../thallus/TInputTexto';
import { TLink } from '../thallus';

import * as storage from '../../storage/';
import * as api from '../../api';

// import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

/**
 * Formulario de ingreso
 * @returns {JSX.Element}
 */
function TFormularioIngresar() {
  const navigate = useNavigate();
  const qc = useQueryClient();

  const [formulario, setFormulario] = useState({
    'th-username': '',
    'th-password': ''
  });

  const [errores, setErrores] = useState({
    'th-username': null,
    'th-password': null,
    login: null
  });

  const [credenciales, setCredenciales] = useState(null);

  const { token, cargando, error, isError } = useObtenerDatos({
    nombre: 'token',
    llamadaAPI: api.autenticacion.login,
    parametros: { credenciales },
    condicion: !!credenciales,
    nofresh: true,
    usaToken: false
  });

  useEffect(() => {
    if (token) {
      storage.token.guardar(token.token);
      qc.clear();
      navigate('/');
    }
  }, [navigate, token, qc]);

  useEffect(() => {
    if (error) {
      setCredenciales(null);
      setErrores({ 'th-username': null, 'th-password': null, login: error.message });
    }
  }, [cargando, error]);

  const handleOnLoginSubmit = (event) => {
    event.preventDefault();

    setErrores({
      'th-username': null,
      'th-password': null,
      login: null
    });

    let loginData = {};
    let err = {};

    for (let k in formulario) {
      if (k.startsWith('th-')) {
        if (formulario[k] === '') {
          err[k] = 'Campo requerido.';
        } else if (k === 'th-username' && formulario[k] !== '' && !formulario[k].includes('@')) {
          err[k] = 'Dirección de correo no válida.';
        } else {
          loginData[k.slice(3)] = formulario[k];
          err[k] = null;
        }
      }
    }

    setErrores({ ...err });

    if (Object.values(err).filter((e) => e).length === 0) {
      setCredenciales(loginData);
    }
  };

  const handleChange = (prop) => (event) => {
    setFormulario({ ...formulario, [prop]: event.target.value });
  };

  useEffect(() => {
    if (isError) {
      setCredenciales(null);
    }
  }, [isError]);

  return (
    <Box component='form' noValidate autoComplete='off' sx={{ marginY: 2.5 }} onSubmit={handleOnLoginSubmit}>
      <Stack spacing={2.7}>
        <TInputTexto
          id='th-username'
          value={formulario.correo}
          onChange={handleChange('th-username')}
          required
          label='Correo Corporativo'
          error={errores['th-username']}
          variant='outlined'
          placeholder='Ejemplo@thallus.cl'
          autoComplete={'email'}
        />
        <TInputPassword
          value={formulario.password}
          onChange={handleChange('th-password')}
          error={errores['th-password']}
          variant='outlined'
        />
        {errores.login && (
          <Alert
            variant='filled'
            severity='error'
            sx={{
              marginTop: '5px !important',
              borderRadius: '12px',
              '& .MuiAlert-message, .MuiAlert-icon': { py: 0 }
            }}
          >
            {errores.login}
          </Alert>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1em'
          }}
        >
          <TLink
            to='/autenticacion/password/restablecer'
            // onClick={() => {} /*() => gaEventTracker('Recuperar Contraseña')*/}
            style={{ textDecoration: 'none' }}
          >
            <Typography fontSize={13} color='#5595BD'>
              Olvidé mi contraseña
            </Typography>
          </TLink>
          <TBotonSubmit>
            {cargando ? <CircularProgress size={13} color='terciary' /> : 'Iniciar Sesión'}
          </TBotonSubmit>
        </div>
      </Stack>
    </Box>
  );
}

export default TFormularioIngresar;
