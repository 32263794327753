import { Autocomplete, TextField } from '@mui/material';

function TSelect({
  id,
  label,
  placeholder,
  opciones,
  onChange = null,
  multiple = false,
  style = null,
  value = undefined,
  width = null
}) {
  return (
    <Autocomplete
      id={`select-${id}`}
      {...(multiple && { multiple: true })}
      {...(multiple && { inputValue: value?.nombre ? value.nombre : '' })}
      value={value || null}
      size='small'
      options={opciones}
      getOptionLabel={(option) => (option?.nombre ? option.nombre : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label={label ?? id}
          placeholder={placeholder ?? id}
        />
      )}
      sx={{
        '& .MuiInput-root': { fontSize: '0.9rem' },
        ...(style && { ...style }),
        ...(width && { width })
      }}
    />
  );
}

export default TSelect;
