/**
 * @module app/components/servicios/rv/MesItem
 */
import { Box, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * Item en vista de meses
 * @returns {JSX.Element}
 */
function MesItem({ proceso }) {
  const navigate = useNavigate();

  const onMesClick = (event) => {
    navigate('/servicios/rv/proceso-mensual/archivos', {
      replace: true,
      state: { contexto: { grupoModelo: proceso.grupo_modelo, proceso: proceso.codigo } }
    });
  };

  if (!proceso) {
    return (
      <Box sx={{ ...css.root }} onClick={() => {}}>
        <Typography sx={{ ...css.item, ...css.monthTitle }}>
          <Skeleton variant='text' sx={{ fontSize: { xs: 14, lg: 17 } }} width={50} animation='wave' />
        </Typography>
        <Typography sx={{ ...css.item }}>
          <Skeleton variant='text' sx={{ fontSize: { xs: 14, lg: 17 } }} width={120} animation='wave' />
        </Typography>
        <Typography sx={{ ...css.item }}>
          <Skeleton variant='text' sx={{ fontSize: { xs: 14, lg: 17 } }} width={70} animation='wave' />
        </Typography>
      </Box>
    );
  }

  // TODO: Reemplazar estructura mensual por una tabla, para garantizar columnas alineadas
  return (
    <Box sx={{ ...css.root }} onClick={onMesClick}>
      <Typography sx={{ ...css.item, ...css.monthTitle }}>{proceso.nombre}</Typography>
      <Typography sx={{ ...css.item }}>
        {proceso?.archivos === 0 ? 'Sin archivos' : (proceso?.archivos ?? 0) + ' archivos disponibles'}
      </Typography>
      <Typography
        sx={{
          ...css.item,
          color:
            typeof coloresEstado[proceso.estado] !== 'undefined'
              ? coloresEstado[proceso.estado]
              : 'tentativo.grey'
        }}
      >
        {proceso.estado}
      </Typography>
    </Box>
  );
}

const coloresEstado = {
  'SIN PROCESAR': 'tentativo.grey',
  'EN PROCESO': 'tentativo.grey',
  CALCULANDO: '#F2CC63',
  PROCESADO: '#539170',
  CERRADO: '#539170'
};

const css = {
  root: {
    width: '100%',
    minWidth: 850,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    justifyContent: 'space-between',
    px: 5,
    py: { xs: 1.5, lg: 2.5 },
    my: { xs: 1, lg: 1 },
    borderRadius: 12,
    boxShadow: '0 2px 11px 0 rgba(69, 81, 76, 0.14)'
  },
  item: {
    fontSize: { xs: 14, lg: 17 },
    width: '33%'
  },
  monthTitle: { fontWeight: 'medium', overflow: 'hidden' }
};

export default MesItem;
