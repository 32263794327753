import { Fragment, useState } from 'react';

import { Box, FormControl, MenuItem, Select, Typography, Paper, Grid } from '@mui/material';

function Indicadores({ datos: indicadores }) {
  const [posicion, setPosicion] = useState('Todas');
  const [base, setBase] = useState('Todas');

  let posiciones, datos;
  if (indicadores && !Array.isArray(indicadores)) {
    posiciones = Object.keys(indicadores.posiciones).map((pos) => {
      return { id: pos, label: indicadores.posiciones[pos] };
    });
    datos = indicadores.valores.filter((val) => val.base === base && val.posicion === posicion);
  }

  const cabecera = (
    <>
      <Box sx={{ ...css.cardHeader, mb: { md: 0, lg: 1 }, mt: { md: 2, lg: 0 } }}>
        <h2>
          Indicadores {!Array.isArray(indicadores) && indicadores?.inDev ? '(EN DESARROLLO)' : ''}
          <>
            <FormControl sx={{ my: 0.5, ml: 1, minWidth: 120 }} size='small'>
              <Select value={posicion} onChange={(val) => setPosicion(val.target.value)} sx={css.select}>
                {posiciones?.map((pos) => (
                  <MenuItem key={pos.id.trim()} value={pos.id.trim()} sx={css.menuitem}>
                    {pos.id === 'Todas' ? 'Posición' : pos.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ my: 0.5, ml: 1, minWidth: 120 }} size='small'>
              <Select value={base} onChange={(val) => setBase(val.target.value)} sx={css.select}>
                {indicadores?.bases.map((_base) => (
                  <MenuItem key={_base.trim()} value={_base.trim()} sx={css.menuitem}>
                    {_base === 'Todas' ? 'Base' : _base}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </h2>
      </Box>
    </>
  );

  return (
    <>
      {cabecera}
      {Array.isArray(indicadores) || !indicadores ? (
        ''
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            '& > * > *': { flexGrow: 1 },
            flexGrow: { sm: 'initial', md: 1, lg: 'initial' }
          }}
          justifyContent='center'
        >
          {Object.entries(indicadores.atributos).map(([key, atributo], i) => (
            <Grid item container xs={6} lg={2} alignItems={'stretch'} key={i}>
              <Paper
                sx={{
                  ...css.card,
                  ...css.indicador,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <h6>{atributo.titulo}</h6>
                <Typography sx={css.indicadorValor}>
                  {formato(atributo.tipo, datos.find((ind) => ind.id === key)?.valor ?? 0, i)}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

const css = {
  card: {
    borderRadius: '16px',
    padding: '10px 18px 18px 18px',
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  cardHeader: {
    '& h2': {
      fontSize: { xs: 17, lg: 20 },
      fontWeight: 600,
      margin: 0,
      padding: 0
    }
  },
  indicador: {
    '& h6': { margin: 0, padding: 0, fontSize: 13, fontWeight: 400 }
  },
  indicadorValor: {
    fontSize: { xs: 24, md: '1.9vw', lg: '1.5vw' },
    fontWeight: 500
  },
  select: {
    fontSize: 14,
    backgroundColor: '#e8e8e8',
    borderRadius: '16px',
    '& fieldset': {
      border: 0
    },
    '& .MuiSelect-select': {
      padding: '2px 16px'
    }
  },
  menuitem: {
    '&:hover': { backgroundColor: '#f5fcf8' }
  }
};

function formato(tipo, valor, i) {
  switch (tipo) {
    case 'moneda':
      return (
        <Fragment key={i}>
          <span style={css.peso}>$</span>
          {Math.round(valor).toLocaleString('es-CL')}
        </Fragment>
      );
    case 'segundos':
      const horas = Math.floor(valor / 3600);
      const minutos = Math.floor((valor % 3600) / 60);
      return (
        <Fragment key={i}>{`${horas.toString().padStart(2, '0')}:${minutos
          .toString()
          .padStart(2, '0')}`}</Fragment>
      );
    case 'porcentaje':
      return <Fragment key={i}>{Math.round(valor)}%</Fragment>;
    case 'dias':
      return <Fragment key={i}>{Math.round(valor)} Días</Fragment>;
    default:
      return <Fragment key={i}>{valor}</Fragment>;
  }
}

export default Indicadores;
