/**
 * @module app/components/thallus/TTabla
 */
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import { useState } from 'react';

// https://mui.com/x/react-data-grid/column-definition/#converting-types
const tipos = {
  personas: {
    align: 'right'
  },
  pesos: {
    valueFormatter: ({ value }) => {
      return !isNaN(value) && value.toString().replace(/\s/g, '') !== ''
        ? `$ ${Number(value).toLocaleString()}`
        : value;
    },
    align: 'right'
  },
  porcentaje: {
    valueFormatter: ({ value }) => {
      return !isNaN(value) && value.toString().replace(/\s/g, '') !== ''
        ? `${Number(value).toLocaleString()} %`
        : value;
    },
    align: 'right'
  }
};

const css = {
  div: {
    width: '100%',
    transition: 'width .5s ease-in, height .2s ease-in',
    WebkitTransition: 'width .5s ease-in, height .2s ease-in'
  },
  tabla: {
    '& .MuiDataGrid-columnHeaderTitle': { whiteSpace: 'normal' }
  },
  components: {
    panel: {
      sx: {
        '& .MuiPaper-root, .MuiButton-root': {
          color: 'primary.S70'
        },
        '& .MuiSwitch-thumb, .Mui-focused': {
          color: 'primary.dark'
        }
      }
    }
  }
};

/**
 * @param {object} params
 * @param {array} params.filas
 * @param {array} params.columnas
 * @param {boolean} params.selectOnClick
 * @param {boolean} params.checkboxes
 * @param {string} params.density (standard|compact|comfortable)
 * @returns {JSX.Element}
 */
function TTabla({
  filas = [],
  columnas = [],
  paginar = true,
  selectOnClick = false,
  checkboxes = false,
  loading = false,
  density = 'standard',
  toolbar = true,
  style = null,
  footer = true,
  campoId = null,
  onClick
}) {
  const [paginacion, setPaginacion] = useState(20);

  let props = {
    density,
    loading,
    autoHeight: true,
    columnTypes: tipos,
    rows: filas,
    columns: columnas,
    componentsProps: css.components,
    disableColumnMenu: true,
    hideFooter: !footer,
    components: {
      NoRowsOverlay: () => '',
      ...(toolbar && { Toolbar })
    },
    getRowHeight: () => 'auto'
  };

  if (style) {
    props.sx = { ...props.sx, ...style };
  }

  if (paginar) {
    props.pageSize = paginacion;
    props.rowsPerPageOptions = [5, 10, 20, 50, 100];
    props.onPageSizeChange = (pageSize) => setPaginacion(pageSize);
    props.pagination = true;
  }

  if (checkboxes) {
    props.checkboxSelection = true;
  }

  if (selectOnClick) {
    props.isableSelectionOnClick = true;
    props.onRowClick = (params, event, details) => {
      onClick(params.row);
    };
  }

  if (campoId) {
    props.getRowId = (row) => row[campoId];
  }

  // TODO: implementar celdas editables
  // if (true) {
  //   props = {
  //     ...props,
  //     experimentalFeatures: { newEditingApi: true },
  //     onCellEditStop: (params, event) => {
  //       console.log(params, event);
  //     }
  //   };
  // }

  return (
    <div style={{ ...css.div }}>
      <DataGrid {...props} />
    </div>
  );
}

function Toolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ color: 'primary.dark' }} />
      <GridToolbarFilterButton sx={{ color: 'primary.dark' }} />
    </GridToolbarContainer>
  );
}

export default TTabla;
