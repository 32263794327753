/**
 * @module app/components/autenticacion/Logo
 */

import logo from '../../assets/img/logo.png';
import logoIsoColor from '../../assets/svg/ISOTIPO_COLOR.svg';
import marca from '../../assets/img/marca.png';

/**
 * Logo thallus
 * @returns {JSX.Element}
 */
function Logo({ style, variation }) {
  /* Nota: Los SVG ahora presentes los armé en Ilustrator en base al único .AI disponible.*/
  switch (variation) {
    case 'isotipoColor':
      return <img alt='LOGO' src={logoIsoColor} style={style} />;
    case 'marca':
      return <img alt='LOGO' src={marca} style={style} />;
    default:
      return <img alt='LOGO' src={logo} style={style} />;
  }
}

export default Logo;
