/**
 * Llamadas a api de autorización
 * @module app/api/interno
 */

import { interno } from './_instancias';

/**
 * Obtener datos empresa
 * @async
 * @param {object} params
 * @param {string} params.token
 * @returns {object}
 */
const obtenerEmpresa = async (empresa) => (await interno.get(`/empresas/${empresa}`)).data;

const obtenerServicios = async () => (await interno.get(`/servicios`)).data;

const inicioBienvenida = async () => (await interno.get('/bienvenida')).data;

const obtenerMenuUsuario = async () => (await interno.get('/menu/usuario')).data;

const obtenerMenus = async () => (await interno.get('/menu')).data;

const crearMenu = async ({ menu }) => (await interno.post('/menu', menu)).data;

const editarMenu = async ({ menu }) => (await interno.put('/menu', menu)).data;

export {
  obtenerEmpresa,
  obtenerServicios,
  inicioBienvenida,
  obtenerMenuUsuario,
  obtenerMenus,
  crearMenu,
  editarMenu
};
