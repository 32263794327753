/**
 * @module app/components/servicios/Sidebar
 */
import { useSelector } from 'react-redux';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Divider, IconButton, List, useTheme, Box, Grid, Typography, Skeleton } from '@mui/material';

import marca from '../../assets/svg/jetsmart_temporal.svg';
import { DrawerHeader, Drawer } from './utils';

import Logo from '../autenticacion/Logo';
import SidebartItem from './SidebarItem';

import * as state from './../../state';

/**
 * Barra menu lateral
 * @param {object} params
 * @param {boolean} params.open
 * @param {Function} params.cerrar
 * @returns {JSX.Element}
 */
function Sidebar({ open, menu, servicioActivo, cerrar }) {
  const theme = useTheme();
  const isMobile = useSelector(state.config.isMobile);

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor='left'
      open={open}
      {...(isMobile && { onKeyDown: cerrar })}
    >
      <DrawerHeader sx={{ minHeight: '80px !important', textAlign: 'center' }}>
        <img alt='LOGO' src={marca} style={{ maxWidth: '80%', maxHeight: '100%', paddingRight: '15px' }} />
        <IconButton onClick={cerrar}>
          {theme.direction === 'rtl' ? (
            <ChevronRight sx={{ color: 'primary.S10' }} />
          ) : (
            <ChevronLeft sx={{ color: 'primary.S10' }} />
          )}
        </IconButton>
      </DrawerHeader>
      {!!menu ? (
        <Menu menu={menu} servicioActivo={servicioActivo} open={open} />
      ) : (
        <List sx={{ padding: '10px', minHeight: 'calc(100vh - 80px - 110px)' }}>
          <Skeleton
            variant='rounded'
            sx={{
              padding: 2,
              margin: 2
            }}
            height={40}
          />
          <Skeleton
            variant='rounded'
            sx={{
              padding: 2,
              margin: 2
            }}
            height={40}
          />
          <Skeleton
            variant='rounded'
            sx={{
              padding: 2,
              margin: 2
            }}
            height={40}
          />
        </List>
      )}
      <Box>
        <Grid container direction='row' alignItems='center' padding='0 18px'>
          <Grid item xs={4}>
            <Logo variation='isotipoColor' />
          </Grid>
          <Grid item xs={8}>
            <Typography fontWeight={600} fontSize={12} sx={{ pl: 1 }}>
              © 2012 THALLUS SPA
            </Typography>
          </Grid>
        </Grid>
        {/* <Box padding='0 18px'>
          <Typography fontSize={11} whiteSpace='normal' sx={footerColors}>
            <a href='https://thallus.cl'>Términos del Servicio</a>
            <span>-</span>
            <a href='https://thallus.cl'>Condiciones de Facturación</a>
            <span>-</span>
            <a href='https://thallus.cl'>Política de Privacidad</a>
          </Typography>
        </Box> */}
      </Box>
    </Drawer>
  );
}

function Menu({ menu, servicioActivo, open }) {
  const servicio = menu.find((m) => m.ruta === servicioActivo);

  return (
    <List sx={{ padding: '10px', minHeight: 'calc(100vh - 80px - 110px)' }}>
      <Typography sx={{ pl: 1 }}>{servicio.nombre}</Typography>
      <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.4)', my: 1 }} />
      {servicio.hijos.map((ruta) => (
        <SidebartItem key={ruta.codigo || ruta.ruta} item={ruta} sidebarOpen={open} />
      ))}
    </List>
  );
}

const footerColors = {
  '& > a': {
    color: '#4B635B',
    textDecoration: 'none'
  },
  '& > a:hover': {
    color: '#0B1F14',
    textDecoration: 'underline'
  }
};

export default Sidebar;
