/**
 * @module app/components/autenticacion/RestablecerPassword
 */

import { Grid, Typography } from '@mui/material';

import TFormularioRestablecerPassword from './TFormularioRestablecerPassword';
import TAuthCard from './TAuthCard';

import { TLink } from '../thallus';

/**
 * Contenedor formulario restablecimiento de password
 * @returns {JSX.Element}
 */
function RestablecerPassword() {
  return (
    <Grid item container xs={12} md={12}>
      <Grid item xs={12} container direction='column' alignItems='center' justifyContent='center'>
        <TAuthCard>
          <Typography textAlign='left' color='primary.S80' sx={css.h3}>
            Restablece tu contraseña:
          </Typography>
          <TFormularioRestablecerPassword />
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
            <TLink to='/autenticacion/ingresar' style={{ textDecoration: 'none' }}>
              <Typography fontSize={13} color='#5595BD'>
                Volver
              </Typography>
            </TLink>
          </div>
        </TAuthCard>
      </Grid>
    </Grid>
  );
}

// TODO : Mover a theme.js u similar
const css = {
  h3: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '1.8rem'
  },
  h5: {
    fontFamily: 'Poppins',
    fontWeight: '300',
    fontSize: '1.25rem'
  }
};

export default RestablecerPassword;
